import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <div>
                <header id="header" className="nav-transparent">
                    <div className="navb">
                        <Link to="/">
                            <img
                                className="logokui"
                                src="assets/logoasia.png"
                                alt=""
                            />
                        </Link>
                        <input className="menu-btn" type="checkbox" id="menu-btn" />
                        <label
                            className="menu-icon"
                            htmlFor="menu-btn"
                            style={{ marginTop: "6px" }}
                        >
                            <span className="navicon" />
                        </label>
                        <ul className="menu">
                            <li>
                                <Link to="/" id="suka">
                                    <span className="indo">Beranda</span>
                                    <span className="english hide">Home</span>
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <div className="row">
                                        <a>Lang &nbsp;<p className="fa fa-language"></p></a>
                                    </div>
                                    <div
                                        className="dropdown-content"
                                        id="manukdadali"
                                        style={{ marginLeft: "-38px" }}
                                    >
                                        <a type="button" id="suka" className="indonesia">
                                            Indonesia
                                        </a>
                                        <a
                                            type="button"
                                            id="suka"
                                            className="englishku"
                                        >
                                            English
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;