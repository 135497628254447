import React, { Component } from 'react';

class Galeri extends Component {
    render() {
        return (
            <div>
                {/* galeri  */}
                <div className="container" id="galeris" data-aos="fade-right">
                    <div className="jumbotron galeris2">
                        <h3 className="gale indo">Galeri Area</h3>
                        <h3 className="gale english hide">Galerry Area</h3>
                        <div className="row hpilang">
                            <a href="assets/galeri/1.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/1.jpg" alt=""
                                />
                            </a>
                            <a href="assets/galeri/2.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/2.jpg" alt=""
                                />
                            </a>
                            <a href="assets/galeri/3.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/3.jpg" alt=""
                                />
                            </a>
                            <a href="assets/galeri/4.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/4.jpg" alt=""
                                />
                            </a>
                            <a href="assets/galeri/5.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/5.jpg" alt=""
                                />
                            </a>
                            <a href="assets/galeri/6.jpg" target="blank" data-aos="flip-right">
                                <img className="imggaleris"
                                    src="assets/galeri/6.jpg" alt=""
                                />
                            </a>
                        </div>
                        {/* tampilan hp */}
                        <div id="carouselExampleControls" className="carousel slide hpcarousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="imggaleris"
                                        src="assets/galeri/1.jpg" alt=""
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img className="imggaleris"
                                        src="assets/galeri/2.jpg" alt=""
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img className="imggaleris"
                                        src="assets/galeri/3.jpg" alt=""
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img className="imggaleris"
                                        src="assets/galeri/4.jpg" alt=""
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img className="imggaleris"
                                        src="assets/galeri/5.jpg" alt=""
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img className="imggaleris"
                                        src="assets/galeri/6.jpg" alt=""
                                    />
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" style={{ color: 'black', paddingRight: '20px' }}>
                                <span className="fa fa-angle-left"></span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" style={{ color: 'black', paddingLeft: '20px' }}>
                                <span className="fa fa-angle-right"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Galeri;