import React from 'react';

function Footer() {

    return (
        <div>
            <a className="thumbwa" href="https://api.whatsapp.com/send?phone=6281232321185" target="__blank">
                <i className="mdi mdi-whatsapp" style={{ fontSize: '20px', position: 'absolute', marginTop: '-5px', marginLeft: '-20px' }}></i>
            &nbsp;
            <span className="indo">hubungi kami</span>
                <span className="english hide">contact us</span>
            </a>
            <footer>
                <div className="container row boxfooter">
                    <div className="col-sm-6">
                        <p className="titlefoot">Secretariat :</p>
                        <p className="jalanfoot">
                            Jl. Soekarno Hatta Rembuksari 1 A Malang
                        <br />
                        Phone : 0341- 478877
                        <br />
                        Fax : 0341-4345225
                        <br />
                        website : <a href="https://asia.ac.id/">asia.ac.id</a>
                            <br />
                        email : birokerjasama@asia.ac.id
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <img className="logofoot" src="assets/logoasia2.png" alt="" />
                        <p className="copyfooter">copyright &#169; UPT-SI Institut Asia Malang</p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;