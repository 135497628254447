import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import HeadDash from '../templates/headDash';
import NavDash from '../templates/navDash';
import ArtTable from './artTable';

class ArtDash extends Component {
    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    componentDidMount = () => {
        this.jsq();

        this.timer = setInterval(() => this.cek(), 1000);
    }

    cek() {
        if (sessionStorage.getItem("un") == null) {
          this.props.history.push('/loadingout')
        }
      }

    render() {
        return (
            <Fragment>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid custisi">
                            <div>
                                <div className="row">
                                    <h2 className="titlemu" style={{ fontWeight: 'bold', color: '#1e2a57' }}>Artikel Berita KUI</h2>
                                </div>
                                <Link to="/artDashadd" className="btn btn-md btn-success" style={{ marginTop: '10px' }}>
                                    + tambah artikel
                                </Link>
                                <div style={{ marginTop: '30px' }} />
                                <ArtTable />
                                <div style={{ marginBottom: '50px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ArtDash;