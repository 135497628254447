import React, { Component } from 'react';
import HeadDash from '../templates/headDash';
import NavDash from '../templates/navDash';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import CKEditor from 'ckeditor4-react';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import swal from 'sweetalert';

class AddArt extends Component {
    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    componentDidMount = () => {
        this.jsq();
        this.timer = setInterval(() => this.cek(), 1000);
    }

    cek() {
        if (sessionStorage.getItem("un") === null) {
            this.props.history.push('/loadingout')
        } else if (sessionStorage.getItem("un") === undefined) {
            this.props.history.push('/loadingout')
        }
    }

    state = {
        gbr_berita: '',
        judul_berita: "",
        tgl_berita: "",
        isi_berita: "",
        id_hidden: 1,
        level_id: 0,
    }

    // file change
    fileChange = (event) => {
        document.getElementById('formGbrArt').src = URL.createObjectURL(event.target.files[0]);
        let gbr_artNew = { ...this.state.gbr_berita };
        gbr_artNew = event.target.files[0];
        this.setState({
            gbr_berita: gbr_artNew
        })

        let fi = document.getElementById('gbrArt');
        let filemui = document.getElementById('gbrArt');

        if (/(\.jpg|\.jpeg|\.png)$/i.test(filemui.files[0].name) === false) {
            document.getElementById('gbrArt').value = '';
            document.getElementById('formGbrArt').src = '';
            swal({
                icon: 'info',
                title: 'Form Gambar',
                text: 'File harus berformat gambar'
            })
            return false
        } else {
            if (fi.files.length > 0) {
                for (var i = 0; i <= fi.files.length - 1; i++) {
                    var fsize = fi.files.item(i).size;
                    const file = Math.round((fsize / 1024));
                    if (file > 1024) {
                        document.getElementById('gbrArt').value = '';
                        document.getElementById('formGbrArt').src = '';
                        swal({
                            icon: 'info',
                            title: 'Form Gambar',
                            text: 'File harus kurang dari 1mb'
                        })
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
    }

    // tgl onChange
    tglChange = (event, selectedDates) => {
        let tgl_artNew = { ...this.state.tgl_berita };
        tgl_artNew = selectedDates;
        this.setState({
            tgl_berita: tgl_artNew
        })
    }

    // input berubah 
    artChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    ckChange = (e, editor) => {
        const data = e.editor.getData();
        this.setState({
            isi_berita: data
        })
    }

    validasiArt = (event) => {
        event.preventDefault()
        var gbr = document.getElementById("gbrArt").value;
        var judul = document.getElementById("judul").value;
        var tgl = document.getElementById("tgl").value;
        var ckIsi = this.state.isi_berita;

        if (gbr === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form gambar wajib diisi !'
            })
        } else if (judul === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form judul wajib diisi !'
            })
        } else if (tgl === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form tanggal wajib diisi !'
            })
        } else if (ckIsi === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form isi berita wajib diisi !'
            })
        } else {
            let newVal = { ...this.state.id_hidden }
            newVal = 1
            this.setState({
                id_hidden: newVal
            })
            this.handleKirimArt();
        }
    }

    validasiArt2 = () => {
        var gbr = document.getElementById("gbrArt").value;
        var judul = document.getElementById("judul").value;
        var tgl = document.getElementById("tgl").value;
        var ckIsi = this.state.isi_berita;
        this.setState({
            id_hidden: 0
        })

        if (gbr === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form gambar wajib diisi !'
            })
        } else if (judul === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form judul wajib diisi !'
            })
        } else if (tgl === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form tanggal wajib diisi !'
            })
        } else if (ckIsi === "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form isi berita wajib diisi !'
            })
        } else {
            this.handleSimpanArt();
        }

    }

    handleKirimArt = async () => {
        let btnpengq = document.getElementById("btnart");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("gbrArt").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);
        document.getElementById("btnart2").classList.add("sembunyi")

        const formData = new FormData();

        formData.append(
            "gbr_berita",
            this.state.gbr_berita,
            this.state.gbr_berita.name,
            this.state
        );

        const sct = sessionStorage.getItem("un");
        await API.post('tambah/artikel/add' + Keykui.keyKui + '&uid=' + sct, this.state
        )
            .then(res =>
                this.setState({
                    status: res.data[0].status,
                    id: res.data[0].id
                })
            )

        if (this.state.status === 1) {
            await API.post('upload/artikel/add' + Keykui.keyKui + '&id=' + this.state.id, formData,
                {
                    mode: 'no-cors',
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then((res) => {
                swal({
                    title: 'Publish berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/artDash');
                })
            })
        }
    }

    handleSimpanArt = async () => {
        let btnpengq = document.getElementById("btnart2");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("gbrArt").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);
        document.getElementById("btnart").classList.add("sembunyi")

        const formData = new FormData();

        formData.append(
            "gbr_berita",
            this.state.gbr_berita,
            this.state.gbr_berita.name,
            this.state
        );

        const sct = sessionStorage.getItem("un");
        await API.post('simpan/artikel/simpan' + Keykui.keyKui + '&uid=' + sct, this.state
        )
            .then(res =>
                this.setState({
                    status: res.data[0].status,
                    id: res.data[0].id
                })
            )

        if (this.state.status === 1) {
            await API.post('upload/artikel/add' + Keykui.keyKui + '&id=' + this.state.id, formData,
                {
                    mode: 'no-cors',
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then((res) => {
                swal({
                    title: 'Simpan berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/artDash');
                })
            })
        }
    }

    render() {
        function bukaTtg2() {
            document.getElementById("ttgkui2").classList.remove("ilang");
            document.getElementById("ttgkui2").classList.add("modal");
        }
        function tutupTtg2() {
            document.getElementById("ttgkui2").classList.remove("modal");
            document.getElementById("ttgkui2").classList.add("ilang");
        }
        return (
            <div>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid custisi">
                            <div className="row">
                                <Link to="/artDash" style={{ fontWeight: 'bold', color: '#1e2a57', fontSize: '20px' }} className="none-hp">
                                    <i className="fas fa-arrow-circle-left"></i>&nbsp;&nbsp;kembali
                                </Link>
                                <h2 className="titlemu" style={{ fontWeight: 'bold', color: '#1e2a57' }}>Add Artikel KUI</h2>
                            </div>
                            <div className="row"
                                style={{
                                    marginTop: '20px', backgroundColor: 'white', padding: '20px',
                                    borderRadius: '20px'
                                }}>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="judul">Judul Artikel</label>
                                        <textarea type="text" name="judul_berita" className="form-control"
                                            onChange={this.artChange} id="judul" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="file">Foto Headline Utama</label>
                                        <input type="file" name="gbr_berita" className="form-control"
                                            onChange={this.fileChange}
                                            id="gbrArt"
                                        />
                                    </div>
                                    <button onClick={bukaTtg2} className="btn btn-secondary">
                                        Lihat gambar
                                    </button>
                                    {/* modal gbr artikel */}
                                    <div id="ttgkui2" className="ilang">
                                        <div className="modal-content2">
                                            <button onClick={tutupTtg2} className="close">
                                                ×
                                            </button>
                                            <br />
                                            <img src="" alt="gambar belum dipilih" className="formgbrpeng" id="formGbrArt" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 spa">
                                    <div className="form-group">
                                        <label htmlFor="judul">Tanggal Artikel</label>
                                        <Flatpickr
                                            className="form-control"
                                            value=""
                                            onChange={this.tglChange}
                                            id="tgl"
                                        />
                                    </div>
                                    <br />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="form-group">
                                        <label htmlFor="judul">Isi Artikel</label>
                                        <CKEditor
                                            data=""
                                            onChange={this.ckChange}
                                            id="ckq"
                                            name="ckq"
                                        />
                                    </div>
                                    <br />
                                    <button onClick={this.validasiArt} className="btn btn-success" style={{ float: 'right', color: 'white' }}
                                        id="btnart"
                                    >
                                        Publish
                                    </button>
                                    <button onClick={this.validasiArt2} className="btn btn-primary mr-2" style={{ float: 'right', color: 'white' }}
                                        id="btnart2"
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddArt;