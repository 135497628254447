import React, { Component } from 'react';
import HeadDash from '../templates/headDash';
import NavDash from '../templates/navDash';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import swal from 'sweetalert';

class AddPeng extends Component {

    state = {
        judul_peng: "",
        gbr_peng: '',
        tgl_peng: "",
        id_hidden: "",
        id_level: 0,
    }

    // file change
    fileChange = (event) => {
        document.getElementById('addFrame').src = URL.createObjectURL(event.target.files[0]);
        let gbr_pengNew = { ...this.state.gbr_peng };
        gbr_pengNew = event.target.files[0];
        this.setState({
            gbr_peng: gbr_pengNew
        })

        let fi = document.getElementById('inputpengfile1');
        let filemui = document.getElementById('inputpengfile1');
        // cek ukuran mb 
        if (/(\.jpg|\.jpeg|\.png)$/i.test(filemui.files[0].name) === false) {
            document.getElementById('inputpengfile1').value = '';
            document.getElementById('addFrame').src = '';
            swal({
                icon: 'info',
                title: 'Form Gambar',
                text: 'File harus berupa gambar'
            })
        } else {
            if (fi.files.length > 0) {
                for (var i = 0; i <= fi.files.length - 1; i++) {
                    var fsize = fi.files.item(i).size;
                    const file = Math.round((fsize / 1024));
                    if (file > 1024) {
                        document.getElementById('inputpengfile1').value = '';
                        document.getElementById('addFrame').src = '';
                        swal({
                            icon: 'info',
                            title: 'Form Gambar',
                            text: 'File harus kurang dari 1mb'
                        })
                    } else {

                    }
                }
            }
        }
    }

    // tgl onChange
    tglChange = (event, selectedDates) => {
        let tgl_pengNew = { ...this.state.tgl_peng };
        tgl_pengNew = selectedDates;
        this.setState({
            tgl_peng: tgl_pengNew
        })
        console.log(tgl_pengNew)
    }

    // input berubah 
    pengChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(e.target.name + "=>" + e.target.value)
    }

    validasiForm = () => {
        var gbr = document.getElementById("inputpengfile1").value;
        var judul = document.getElementById("judul").value;
        var tgl = document.getElementById("tgl").value;

        if (gbr == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form gambar wajib diisi !'
            })
        } else if (judul == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form judul wajib diisi !'
            })
        } else if (tgl == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form tanggal wajib diisi !'
            })
        } else {
            this.handleKirimPeng()
        }
    }

    validasiForm2 = () => {
        var gbr = document.getElementById("inputpengfile1").value;
        var judul = document.getElementById("judul").value;
        var tgl = document.getElementById("tgl").value;

        if (gbr == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form gambar wajib diisi !'
            })
        } else if (judul == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form judul wajib diisi !'
            })
        } else if (tgl == "") {
            swal({
                icon: 'info',
                title: 'Gagal menambah',
                text: 'Form tanggal wajib diisi !'
            })
        } else {
            this.setState({
                id_hidden: 0
            })
            this.handleSimpanPeng()
        }
    }

    handleKirimPeng = async () => {
        let btnpengq = document.getElementById("btnpeng");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("inputpengfile1").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);
        document.getElementById("btnpeng2").classList.add("sembunyi");

        const formData = new FormData();

        formData.append(
            "gbr_peng",
            this.state.gbr_peng,
            this.state.gbr_peng.name,
            this.state
        );

        const sct = sessionStorage.getItem("un");
        // console.log(sct)
        await API.post('tambah/pengumuman/add' + Keykui.keyKui + '&uid=' + sessionStorage.getItem("un"), this.state)
            .then(res =>
                this.setState({
                    status: res.data[0].status,
                    id: res.data[0].id
                })
            )
        if (this.state.status == 1) {
            await API.post('upload/pengumuman/add' + Keykui.keyKui + '&id=' + this.state.id, formData,
                {
                    mode: 'no-cors',
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then((res) => {
                swal({
                    title: 'Publish berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/pgDash')
                })
            })
        }
    }

    handleSimpanPeng = async () => {
        let btnpengq = document.getElementById("btnpeng2");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("inputpengfile1").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);
        document.getElementById("btnpeng").classList.add("sembunyi");

        const formData = new FormData();

        formData.append(
            "gbr_peng",
            this.state.gbr_peng,
            this.state.gbr_peng.name,
            this.state
        );

        const sct = sessionStorage.getItem("un");
        // console.log(sct)
        await API.post('spn/pengumuman/simpan' + Keykui.keyKui + '&uid=' + sessionStorage.getItem("un"), this.state)
            .then(res =>
                this.setState({
                    status: res.data[0].status,
                    id: res.data[0].id
                })
            )
        if (this.state.status == 1) {
            await API.post('upload/pengumuman/add' + Keykui.keyKui + '&id=' + this.state.id, formData,
                {
                    mode: 'no-cors',
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then((res) => {
                swal({
                    title: 'Simpan berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/pgDash')
                })
            })
        }
    }

    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.jsq();
        }, 100)
        this.timer = setInterval(() => this.cek(), 1000);
    }

    cek() {
        if (sessionStorage.getItem("un") == null) {
            this.props.history.push('/loadingout')
        } else if (sessionStorage.getItem("un") == undefined) {
            this.props.history.push('/loadingout')
        }
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <div>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid custisi">
                            <div className="row">
                                <Link to="/pgDash" style={{ fontWeight: 'bold', color: '#1e2a57', fontSize: '20px' }} className="none-hp">
                                    <i className="fas fa-arrow-circle-left"></i>&nbsp;&nbsp;kembali
                                </Link>
                                <h2 className="titlemu" style={{ fontWeight: 'bold', color: '#1e2a57' }}>Add Pengumuman</h2>
                            </div>
                            {/* <form> */}
                            <div className="row"
                                style={{
                                    marginTop: '20px', backgroundColor: 'white', padding: '20px',
                                    borderRadius: '20px'
                                }}>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="file">Foto Pengumuman</label>
                                        <input type="file" name="gbr_peng" className="form-control"
                                            onChange={this.fileChange} id="inputpengfile1"
                                        />
                                    </div>
                                    <img src="" alt="gambar belum dipilih" className="formgbrpeng" id="addFrame" />
                                </div>
                                <div className="col-md-6 spa">
                                    <div className="form-group">
                                        <label htmlFor="judul">Judul Pengumuman</label>
                                        <input type="text" name="judul_peng" className="form-control" onChange={this.pengChange} id="judul" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="judul">Tanggal Pengumuman</label>
                                        <Flatpickr
                                            className="form-control"
                                            value=""
                                            onChange={this.tglChange}
                                            id="tgl"
                                        />
                                    </div>
                                    <br />
                                    <button onClick={this.validasiForm} type="submit" className="btn btn-success" style={{ float: 'right', color: 'white' }}
                                        id="btnpeng"
                                    >
                                        Publish
                                    </button>
                                    <button onClick={this.validasiForm2} type="submit" className="btn btn-primary mr-2" style={{ float: 'right', color: 'white' }}
                                        id="btnpeng2"
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </div>
                            <div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPeng;