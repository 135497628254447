import React, { Component } from 'react';

class Download extends Component {
    render() {
        return (
            <div>
                {/* download  */}
                <div className="container" id="download">
                    <div className="jumbotron down">
                        <h3 className="downl">
                            <span className="indo">Unduhan File</span>
                            <span className="english hide">File Download</span>
                        </h3>
                        <div className="row">
                            <div className="col-md-3">
                                <a href="assets/download/Student Profile.docx" data-toggle="tooltip" title="click to download" download>
                                    <img src="assets/down1.png" className="downimg" alt=""/>
                                    <br />
                                    <h6 className="text-center" style={{ color: '#1e2a57', paddingLeft: '40px', paddingRight: '40px', marginTop: '-5px', marginBottom: '50px' }}>
                                        <span className="indo">Profil Siswa.docx</span>
                                        <span className="english hide">Student Profile.docx</span>
                                    </h6>
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href="assets/download/Student Project Evaluation Form.docx" data-toggle="tooltip" title="click to download" download>
                                    <img src="assets/down2.png" alt="" className="downimg" />
                                    <br />
                                    <h6 className="text-center" style={{ color: '#1e2a57', paddingLeft: '40px', paddingRight: '40px', marginTop: '-5px', marginBottom: '50px' }}>
                                        <span className="indo">Form Evaluasi Proyek Mahasiswa.docx</span>
                                        <span className="english hide">Student Project Evaluation Form.docx</span>
                                    </h6>
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href="assets/download/Surat ijin orangtua.doc" data-toggle="tooltip" title="click to download" download>
                                    <img src="assets/down3.png" alt="" className="downimg" />
                                    <br />
                                    <h6 className="text-center" style={{ color: '#1e2a57', paddingLeft: '40px', paddingRight: '40px', marginTop: '-5px', marginBottom: '50px' }}>
                                        <span className="indo">Surat Ijin Orangtua.docx</span>
                                        <span className="english hide">Parent's Permission Letter.docx</span>
                                    </h6>
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href="assets/download/Internship Report.docx" data-toggle="tooltip" title="click to download" download>
                                    <img src="assets/down4.png" alt="" className="downimg" />
                                    <br />
                                    <h6 className="text-center" style={{ color: '#1e2a57', paddingLeft: '40px', paddingRight: '40px', marginTop: '-5px', marginBottom: '50px' }}>
                                        <span className="indo">Laporan Pertukaran Magang.docx</span>
                                        <span className="english hide">Internship Exchange Report.docx</span>
                                    </h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Download;