import React, { Component, Fragment } from 'react';
import HeadDash from './templates/headDash';
import NavDash from './templates/navDash';

class Dashboard extends Component {

    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    // valLog() {
    //     if (sessionStorage.getItem("adm") == null) {
    //         sessionStorage.clear()
    //         window.location.replace('/loadingout')
    //     }
    //     else if (localStorage.getItem("logus") == null) {
    //         sessionStorage.clear()
    //         window.location.replace('/loadingout')
    //     }
    // }

    componentDidMount = () => {
        setTimeout(() => {
            this.jsq();
        }, 100)
        // this.valLog();
        this.timer = setInterval(() => this.cek(), 1000);
    }

    cek() {
        if (sessionStorage.getItem("un") === null) {
          this.props.history.push('/loadingout')
        }else if (sessionStorage.getItem("un") === undefined) {
            this.props.history.push('/loadingout')
          }
      }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <Fragment>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid">
                            <div className="jumbotron">
                                <h1 id="" style={{ color: "white" }}>
                                    <span className="indo"><b>Dashboard KUI</b></span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Dashboard;