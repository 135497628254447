import React, { Component } from 'react';
import Footer from '../../components/footer';
import API from '../../services/api';
import lkgbr from '../../services/linkgbr';
import Header from '../../components/header';
import Keykui from '../../services/key';

class DetPeng extends Component {

    state = {
        judulpeng: "",
        tglpeng: ""
    }

    //get peng
    async pengDetail() {
        let id = this.props.match.params.pengId;
        await API.get(`pengumuman/${id}` + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            this.setState({
                gbrpeng: lkgbr.pengLk + res.data.data.gbr_peng + Keykui.keyKui,
                judulpeng: res.data.data.judul_peng,
                tglpeng: res.data.data.tgl_peng,
                status: res.status
            })
        })
        if (this.state.status == 200) {
            document.querySelector(".bodyqqq").classList.add("sembunyi")
        }
    }

    perintah = () => {
        var perintah = document.createElement("script");
        perintah.src = "assets/perintah.js";
        document.getElementsByTagName("head")[0].appendChild(perintah);
    };

    componentDidMount = () => {
        if (localStorage.getItem("bahasaPilihan") === "english") {
            this.perintah();
        }
        this.pengDetail();
        // navbar
        window.onscroll = function () { scrollFunction() };
        var myNav = document.getElementById('header');

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                myNav.classList.remove("nav-transparent");
                myNav.classList.add("nav-berwarna");
            } else {
                myNav.classList.remove("nav-berwarna");
                myNav.classList.add("nav-transparent");
            }
        }

        // scroll nav dan for val
        var scriptku = document.createElement("script");
        scriptku.src = "assets/jscustom.js";
        document.getElementsByTagName("head")[0].appendChild(scriptku);

        // animation 
        var aos12 = document.createElement("script");
        aos12.src = "assets/aos.js";
        document.getElementsByTagName("head")[0].appendChild(aos12);
    }

    render() {

        var date = new Date(this.state.tglpeng);
        var tahun = date.getFullYear();
        var bulan = date.getMonth();
        var tanggal = date.getDate();
        switch (bulan) {
            case 0: bulan = "January"; break;
            case 1: bulan = "February"; break;
            case 2: bulan = "March"; break;
            case 3: bulan = "April"; break;
            case 4: bulan = "May"; break;
            case 5: bulan = "June"; break;
            case 6: bulan = "July"; break;
            case 7: bulan = "August"; break;
            case 8: bulan = "September"; break;
            case 9: bulan = "October"; break;
            case 10: bulan = "November"; break;
            case 11: bulan = "December"; break;
            default:
        }
        var tgl_peng = tanggal + " " + bulan + " " + tahun;
        return (
            <div>
                <Header />
                <div className="container">
                    <div className="jumbotron" style={{ marginTop: '30px', backgroundColor: 'transparent' }}>
                        <div className="bodyqqq">
                            <div className="containerqq">
                                <div className="yellow bullet"></div>
                                <div className="red bullet"></div>
                                <div className="blue bullet"></div>
                                <div className="violet bullet"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <p className="tulisanp" style={{ marginLeft: '0' }} data-aos="fade-left">
                                    {this.state.judulpeng}
                                </p>
                                <p data-aos="fade-left" style={{ marginTop: '-10px', marginLeft: '0' }}>
                                    {tgl_peng}
                                </p>
                                <img src={this.state.gbrpeng} alt="" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default DetPeng;