import React, { Component } from 'react'
import API_ASTOR from '../../../services/api_astor';
import keyastor from '../../../services/key_astor';

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            un: '',
            ua: '',
            usd: '',
            aks: '',
            user_nama: '',
            aksusers: ''
        }
    }

    async componentDidMount() {
        if (localStorage.getItem('ad') == null) {
            sessionStorage.clear()
            localStorage.clear()
            window.location.replace('/loadingout')
        }

        const scp = this.props.match.params.un
        const res = await API_ASTOR.get('kua/search/inview' + keyastor.baseURL + '&uid=' + scp)
        this.setState({
            ua: res.data[0],
            un: res.data[0].usid,
            aks: res.data[0].aks,
            user_nama: res.data[0].user_nama
        })

        if (this.state.aks == 0) {
            window.location.replace('/loadingout')
        } else {
            sessionStorage.setItem("un", this.state.un)
            sessionStorage.setItem("adm", this.state.aks)
            sessionStorage.setItem("nama", this.state.user_nama)
            localStorage.setItem("nama_adm", this.state.user_nama)
            localStorage.setItem("logus", JSON.stringify(this.state.ua))
            this.valLog()
        }
    }

    async toDoLogin() {
        const akses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + keyastor.baseURL + '&ak=' + sessionStorage.getItem("adm"))
        this.setState({
            aksusers: akses.data[0].level_id,
        })
        if (this.state.aksusers == 18) {
            localStorage.removeItem('ad')
            window.location.replace('/pgDash')
        } else {
            sessionStorage.clear()
            localStorage.clear()
            window.location.replace('/loadingout')
        }
    }

    valLog() {
        if (sessionStorage.getItem("adm") == null) {
            sessionStorage.clear()
            window.location.replace('/loadingout')
        }
        else if (localStorage.getItem("logus") == null) {
            sessionStorage.clear()
            window.location.replace('/loadingout')
        }
        else {
            this.toDoLogin()
        }
    }

    componentWillUnmount() {
        this.timer = null;
    }

    render() {
        return (
            <div className="bodyqq">
                <div class="containerqq">
                    <div class="yellow bullet"></div>
                    <div class="red bullet"></div>
                    <div class="blue bullet"></div>
                    <div class="violet bullet"></div>
                </div>
            </div>
        )
    }
}
