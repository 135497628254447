import React, { Component } from 'react'

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            un: '',
            ua: ''
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem("un") == null) {
            sessionStorage.clear()
            localStorage.clear()
            window.location.replace('/login')
        } else {
            sessionStorage.clear()
            window.location.replace('/login')
        }
    }

    render() {
        return (
            <div className="bodyqq">
                <div class="containerqq" style={{color: 'white'}}>
                <i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp; Loading Go Out.....
                </div>
            </div>
        )
    }
}
