import React, { Component } from 'react';
import API from '../services/api';
import Keykui from '../services/key';
import swal from 'sweetalert';

class Kontak extends Component {

    constructor(props) {
        super(props);
        this.state = { isipesan: '', nama: '', email: '' };
        this.konChange = this.konChange.bind(this);
        this.postCS = this.postCS.bind(this);
    }

    konChange = event => {
        if (event.target.id === "email") {
            this.setState({ email: event.target.value })
        }
        if (event.target.id === "nama") {
            this.setState({ nama: event.target.value })
        }
        else {
            this.setState({ isipesan: event.target.value })
        }
    }

    // post data
    postCS = (e) => {
        let btnpengq = document.getElementById("btnkon");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>';

        document.getElementById('previous').setAttribute("disabled", true)

        document.getElementById("nama").setAttribute("disabled", true);
        document.getElementById("email").setAttribute("disabled", true);
        document.getElementById("textareacus").setAttribute("disabled", true);

        const serviceId = 'service_u5wapog';
        const templateId = 'template_cbo34ls';
        this.sendFeedback(serviceId, templateId, { message: this.state.isipesan, form_name: this.state.nama, emailq: this.state.email, to_name: 'kui@asia.ac.id' });
        e.preventDefault();
    }

    sendFeedback(serviceId, templateId, variables) {
        window.emailjs.send(
            serviceId, templateId, variables
        ).then(res => {
            swal({
                icon: 'success',
                title: 'Message Sent',
                text: 'wait for our reply in your email'
            }).then(function () {
                window.location.reload();
            }
            );
        })
            .catch(err => console.error('Fail Gaming', err));
    }

    postKu = async () => {
        let btnpengq = document.getElementById("btnkon");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>';

        document.getElementById('previous').setAttribute("disabled", true)

        document.getElementById("nama").setAttribute("disabled", true);
        document.getElementById("email").setAttribute("disabled", true);
        document.getElementById("textareacus").setAttribute("disabled", true);

        await API.post('mailer/mail/add' + Keykui.keyKui, this.state)
            .then(res =>
                this.setState({
                    status: res.status
                })
            )
        if (this.state.status == 200) {
            let btnpengq = document.getElementById("btnkon");
            btnpengq.setAttribute("disabled", true);
            btnpengq.innerHTML = 'success';

            swal({
                icon: 'success',
                title: 'Message Sent',
                text: 'wait for our reply in your email'
            }).then(function () {
                window.location.reload();
            }
            );
        }
    }

    render() {
        return (
            <div>
                {/* kontak kami  */}
                <div className="container" id="kontku">
                    <div className="jumbotron" style={{ backgroundColor: "transparent" }}>
                        <h1 className="kontitle indo">Kontak kami.</h1>
                        <h1 className="kontitle english hide">Contact Us.</h1>
                    </div>
                </div>
                <div className="container" style={{ padding: "0px 25px 0px 25px" }}>
                    <div className="jumbotron row kontakbox">
                        <div className="col-lg-6"></div>
                        <form id="myform" className="col-lg-6" onSubmit={this.postCS} action="#">
                            <h2
                                className="indo"
                                id="siap"
                                style={{
                                    fontSize: "35px",
                                    color: "white",
                                    textAlign: "right",
                                    marginTop: "-20px",
                                    paddingRight: "20px",
                                }}
                            >
                                Form
                            </h2>
                            <h2
                                id="siap"
                                className="english hide"
                                style={{
                                    fontSize: "35px",
                                    color: "white",
                                    textAlign: "right",
                                    marginTop: "-20px",
                                    paddingRight: "20px",
                                }}
                            >
                                Question
                            </h2>
                            <h2
                                style={{
                                    fontSize: "35px",
                                    color: "white",
                                    textAlign: "right",
                                    paddingRight: "20px",
                                }}
                            >
                                <i className="indo">Pertanyaan.</i>
                                <i className="english hide">Form</i>
                            </h2>

                            <fieldset id="s1" style={{ marginTop: "12px" }}>
                                <div className="form-group">
                                    <p
                                        className="ttg1 indo"
                                        style={{
                                            color: "white",
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        Nama Lengkap
                                    </p>
                                    <p
                                        className="ttg1 english hide"
                                        style={{
                                            color: "white",
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        Full Name
                                    </p>
                                    <input
                                        type="text"
                                        id="nama"
                                        className="form-control formcustom"
                                        name="usernameku"
                                        placeholder="full name"
                                        required
                                        onChange={this.konChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <p
                                        className="ttg1"
                                        style={{
                                            color: "white",
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        Email
                                    </p>
                                    <input
                                        className="form-control formcustom"
                                        id="email"
                                        name="emailku"
                                        type="email"
                                        placeholder="email"
                                        required
                                        onChange={this.konChange}
                                    />
                                </div>
                                <p style={{ textAlign: "right" }}>
                                    <a className="btn btn-customku2 next">Next</a>
                                </p>
                            </fieldset>

                            <fieldset id="s2" style={{ marginTop: "12px" }}>
                                <div className="form-group">
                                    <p
                                        className="ttg1 indo"
                                        style={{
                                            color: "white",
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        Pertanyaan
                                    </p>
                                    <p
                                        className="ttg1 english hide"
                                        style={{
                                            color: "white",
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        Question
                                    </p>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="textareacus"
                                        placeholder="Pesan"
                                        rows="8"
                                        name="isipesan"
                                        required
                                        onChange={this.konChange}
                                    />
                                </div>
                                <div className="row" id="kanan">
                                    <p>
                                        <button type="button" className="btn btn-customku3" id="previous">
                                            Previous
                                        </button>
                                    </p>
                                    <p>
                                        <button
                                            className="btn btn-customku4"
                                            id="btnkon"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </p>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Kontak;