import React, { Component } from 'react';

class Strukturorg extends Component {
    render() {
        return (
            <div>
                {/* struktur */}
                <div className="container" id="struk">
                    <div className="jumbotron boxstruk">
                        <h1 className="struk" data-aos="fade-right">
                            <span className="indo">
                                Struktur Organisasi.
                            </span>
                            <span className="english hide">
                                Organizational Structure.
                            </span>
                        </h1>
                        <div className="row">
                            <div className="boximgstruk" data-aos="fade-up">
                                <img className="imgstruk" src="assets/struktur/1.png" alt="ketua kui" />
                                <p className="namastruk">Nur Lailatul Aqromi, SS.,MA</p>
                                <p className="jabstruk">
                                    <span className="indo">Ketua Biro Kerjasama</span>
                                    <span className="english hide">Head Office</span>
                                </p>
                            </div>
                            <div className="boximgstruk" data-aos="fade-down">
                                <img className="imgstruk" src="assets/struktur/2.png" alt="ketua kui" />
                                <p className="namastruk">Siti Nurul Afiyah, S.Si., M.Si</p>
                                <p className="jabstruk">
                                    <span className="indo">Koordinator Kerjasama Internasional </span>
                                    <span className="english hide">Coordinator International</span>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="boximgstruk" data-aos="fade-up">
                                <img className="imgstruk" src="assets/struktur/3.jpeg" alt="ketua kui" />
                                <p className="namastruk">Dr. Ida Nuryana. S.E. M.M</p>
                                <p className="jabstruk">
                                    <span className="indo">Koordinator Kerjasama Nasional</span>
                                    <span className="english hide">Coordinator National</span>
                                </p>
                            </div>
                            <div className="boximgstruk" data-aos="fade-down">
                                <img className="imgstruk" src="assets/struktur/4.jpeg" alt="ketua kui" />
                                <p className="namastruk">El Salwa Sabilla Mufid, S.Ds</p>
                                <p className="jabstruk">
                                    <span className="indo">Staff Admin Biro Kerjasama </span>
                                    <span className="english hide">Office Staff</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Strukturorg;