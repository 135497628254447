import React, { Component } from 'react';
import HeadDash from '../templates/headDash';
import NavDash from '../templates/navDash';
import { Link, Redirect } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import swal from 'sweetalert';
import linkgbr from '../../../services/linkgbr';

class EditPeng extends Component {

    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    state = {
        judul_peng: "",
        gbr_peng: "",
        tgl_peng: "",
        id_hidden: "",
        id_level: "",
    }

    // ..tgl onChange
    tglChange = (event, selectedDates) => {
        let tgl_pengNew = { ...this.state.tgl_peng };
        tgl_pengNew = selectedDates;
        this.setState({
            tgl_peng: tgl_pengNew
        })
    }

    // input berubah 
    pedChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.jsq();
        }, 1000)
        let id = this.props.match.params.pgId;
        API.get(`pengumuman/${id}` + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            let formPeng = res.data;
            this.setState({
                id: formPeng.data.id,
                judul_peng: formPeng.data.judul_peng,
                gbr_peng2: linkgbr.pengLk + formPeng.data.gbr_peng + Keykui.keyKui,
                tgl_peng: formPeng.data.tgl_peng,
                id_hidden: formPeng.data.id_hidden,
                id_level: formPeng.data.id_level
            })
        })
        this.timer = setInterval(() => this.cek(), 1000);
    };

    cek() {
        if (sessionStorage.getItem("un") == null) {
            this.props.history.push('/loadingout')
        } else if (sessionStorage.getItem("un") == undefined) {
            this.props.history.push('/loadingout')
        }
    }

    // file change
    fileCg = (event) => {
        document.getElementById('frame').src = URL.createObjectURL(event.target.files[0]);
        this.setState({
            gbr_peng: event.target.files[0]
        })

        let fi = document.getElementById('editinput2');
        let filemui = document.getElementById('editinput2');

        if (/(\.jpg|\.jpeg|\.png)$/i.test(filemui.files[0].name) === false) {
            document.getElementById('editinput2').value = '';
            document.getElementById('frame').src = linkgbr.pengLk + this.state.gbr_peng2 + Keykui.keyKui;
            swal({
                icon: 'info',
                title: 'Form Gambar',
                text: 'File harus berupa gambar'
            })
            this.setState({
                gbr_peng: ''
            })
            return false;
        } else {
            if (fi.files.length > 0) {
                for (var i = 0; i <= fi.files.length - 1; i++) {
                    var fsize = fi.files.item(i).size;
                    const file = Math.round((fsize / 1024));
                    if (file > 1024) {
                        document.getElementById('editinput2').value = '';
                        document.getElementById('frame').src = linkgbr.pengLk + this.state.gbr_peng2 + Keykui.keyKui;
                        swal({
                            icon: 'info',
                            title: 'Form Gambar',
                            text: 'File harus kurang dari 1mb'
                        })
                        this.setState({
                            gbr_peng: ''
                        })
                        return false;
                    } else {
                        return true
                    }
                }
            }
        }
    }

    handleEditPeng = async () => {
        let btnpengq = document.getElementById("btnpenged");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("editinput2").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);

        await API.put('edit/pengumuman/' + this.state.id + Keykui.keyKui, this.state,
            {
                mode: 'no-cors',
                method: "PUT"
            }
        )
            .then((res) => {
                this.setState({
                    status: res.data.status,
                    idGbr: res.data.id
                })
            })
        if (this.state.status == 1) {
            if (this.state.gbr_peng != "") {
                const formData = new FormData();

                formData.append(
                    "gbr_peng",
                    this.state.gbr_peng,
                    this.state.gbr_peng.name,
                    this.state
                );
                await API.post('upld/pgEdit/add' + Keykui.keyKui + '&id=' + this.state.idGbr, formData,
                    {
                        mode: 'no-cors',
                        method: "POST",
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(() => {
                    swal({
                        title: 'Edit berhasil',
                        text: 'menutup jendela...',
                        icon: 'success',
                        timer: 3000,
                        buttons: false,
                    }).then(function () {
                        window.location.replace('/pgDash')
                    })
                })
            } else {
                swal({
                    title: 'Edit berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/pgDash')
                })
            }
        }
    }

    render() {
        return (
            <div>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid custisi">
                            <div className="row">
                                <Link to="/pgDash" style={{ fontWeight: 'bold', color: '#1e2a57', fontSize: '20px' }} className="none-hp">
                                    <i className="fas fa-arrow-circle-left"></i>&nbsp;&nbsp;kembali
                                </Link>
                                <h2 className="titlemu" style={{ fontWeight: 'bold', color: '#1e2a57' }}>Edit Pengumuman</h2>
                            </div>
                            <div className="row"
                                style={{
                                    marginTop: '20px', backgroundColor: 'white', padding: '20px',
                                    borderRadius: '20px'
                                }}>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="file">Foto Pengumuman</label>
                                        <input type="file" name="fileqq" className="form-control" id="editinput2" onChange={this.fileCg} />
                                    </div>
                                    <img src={this.state.gbr_peng2} alt="gambar belum dipilih" className="formgbrpeng"
                                        id="frame" />
                                </div>
                                <div className="col-md-6 spa">
                                    <div className="form-group">
                                        <label htmlFor="judul_peng">Judul Pengumuman</label>
                                        <input type="text" name="judul_peng" className="form-control"
                                            defaultValue={this.state.judul_peng}
                                            onChange={this.pedChange}
                                            id="judul"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tgl+peng">Tanggal Pengumuman</label>
                                        <Flatpickr
                                            className="form-control"
                                            value={this.state.tgl_peng}
                                            onChange={this.tglChange}
                                            id="tgl"
                                        />
                                    </div>
                                    <br />
                                    <button
                                        id="btnpenged"
                                        onClick={this.handleEditPeng}
                                        className="btn btn-success" style={{ float: 'right', color: 'white' }}>
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditPeng;