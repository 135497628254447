import React, { Component } from 'react';
import { Link } from "react-router-dom";
import API from '../services/api';
import lkgbr from '../services/linkgbr';
import Keykui from '../services/key';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

class Pengumuman extends Component {
    // state 
    state = {
        peng: []
    }

    //get pengumuman
    async pengGet() {
        await API.get('tampil/pengumuman' + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            this.setState({
                peng: res.data,
                statuspeng: res.status
            })
        })

        if (this.state.statuspeng == 202) {
            document.querySelector(".bodyqqqqq").classList.add("sembunyi")
            setTimeout(() => {
                var tableq = document.createElement("script");
                tableq.src = "assets/tableCust.js";
                document.getElementsByTagName("head")[0].appendChild(tableq);
            }, 500)
        }
    }

    componentDidMount = () => {
        this.pengGet();
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <div>
                <Splide>
                    {
                        this.state.peng.map(peng => {
                            var date = new Date(peng.tgl_peng);
                            var tahun = date.getFullYear();
                            var bulan = date.getMonth();
                            var tanggal = date.getDate();
                            switch (bulan) {
                                case 0: bulan = "January"; break;
                                case 1: bulan = "February"; break;
                                case 2: bulan = "March"; break;
                                case 3: bulan = "April"; break;
                                case 4: bulan = "May"; break;
                                case 5: bulan = "June"; break;
                                case 6: bulan = "July"; break;
                                case 7: bulan = "August"; break;
                                case 8: bulan = "September"; break;
                                case 9: bulan = "October"; break;
                                case 10: bulan = "November"; break;
                                case 11: bulan = "December"; break;
                                default:
                            }
                            var tgl_pengum = tanggal + " " + bulan + " " + tahun;
                            return (
                                <SplideSlide key={peng.id}>
                                    <div className="row" style={{ paddingBottom: '6%', paddingLeft: '8%', paddingRight: '8%' }}>
                                        <div className="col-lg-6">
                                            <img src={lkgbr.pengLk + peng.gbr_peng + Keykui.keyKui}
                                                style={{
                                                    width: '100%', height: '300px', objectFit: 'cover',
                                                    borderRadius: '10px',
                                                    boxShadow: '12px 12px 2px 1px rgba(0, 0, 255, .2)',
                                                    border: '2px solid #dbdbdb'
                                                }}
                                                alt="" />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="tulisanp">
                                                {peng.judul_peng}.
                                            </p>
                                            <div className="ketp batasanhidup">
                                                <b>info date : {tgl_pengum} <br /></b>
                                                <div className="indo">
                                                    klik untuk melihat detail pengumuman yang lebih jelas lagi.
                                                </div>
                                                <div className="english hide">
                                                    click to see more detail of announcement.
                                                </div>
                                            </div>
                                            <br />
                                            <Link className="bacap indo" to={"peng" + peng.id}>
                                                baca selengkapnya <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                                            </Link>
                                            <Link className="bacap english hide" to={"peng" + peng.id}>
                                                read more <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                                            </Link>
                                        </div>
                                    </div>
                                </SplideSlide>
                            )
                        })
                    }
                </Splide>

                <div className="bodyqqqqq">
                    <div className="containerqq">
                        <div className="yellow bullet"></div>
                        <div className="red bullet"></div>
                        <div className="blue bullet"></div>
                        <div className="violet bullet"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pengumuman;