import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import lkgbr from '../../../services/linkgbr';
import swal from 'sweetalert';

class PengTable extends Component {
    // state 
    state = {
        peng: []
    }

    hdnChange = (peng) => {
        swal({
            title: "Yakin untuk di hidden?",
            text: "pengumuman tidak akan terlihat di tampilan utama!",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(async (baikdiubah) => {
            if (baikdiubah) {
                let btnpengq = document.getElementById("btnhdn" + peng.id);
                btnpengq.setAttribute("disabled", true);
                btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'
                await API.put('hdn/pengumuman/' + peng.id + Keykui.keyKui,
                    {
                        mode: "no-cors",
                        method: "PUT"
                    }
                )
                    .then(() => {
                        swal({
                            title: 'Hidden berhasil',
                            text: 'menutup jendela...',
                            icon: 'success',
                            timer: 3000,
                            buttons: false,
                        }).then(function () {
                            window.location = "/pgDash";
                        })
                    })
            } else {

            }
        })
    }

    hpsChange = (peng) => {
        swal({
            title: "Yakin untuk di hapus?",
            text: "pengumuman akan dihapus permanen !",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(async (baikdiubah) => {
            if (baikdiubah) {
                let btnpengq = document.getElementById("btnhps" + peng.id);
                btnpengq.setAttribute("disabled", true);
                btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'
                await API.delete('pgHps/pengumuman/add/' + Keykui.keyKui + '&id=' + peng.id,
                    {
                        mode: "no-cors",
                        method: "POST"
                    }
                )
                    .then((res) => {
                        swal({
                            title: 'Hapus berhasil',
                            text: 'menutup jendela...',
                            icon: 'success',
                            timer: 3000,
                            buttons: false,
                        }).then(function () {
                            window.location = "/pgDash";
                        })
                    })
            }
        })
    }

    shwChange = async (peng) => {
        let btnpengq = document.getElementById("btnshw" + peng.id);
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        await API.put('shw/pengumuman/' + peng.id + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "PUT"
            }
        )
            .then(() => {
                swal({
                    title: 'Publish berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location = "/pgDash";
                })
            })
    }

    //get pengumuman
    async pengGet() {
        await API.get('view/pengumumanDash' + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            this.setState({
                peng: res.data,
                status: res.status
            })
        })
        if (this.state.status === 200) {
            document.querySelector(".bodyqqq").classList.add("sembunyi")
            setTimeout(() => {
                var tableq = document.createElement("script");
                tableq.src = "assets/tableCust.js";
                document.getElementsByTagName("head")[0].appendChild(tableq);
            }, 500)
        }
    }

    componentDidMount = () => {
        this.pengGet();
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <Fragment>
                <div className="bodyqqq" style={{padding: '50px'}}>
                    <div className="containerqq">
                        <div className="yellow bullet"></div>
                        <div className="red bullet"></div>
                        <div className="blue bullet"></div>
                        <div className="violet bullet"></div>
                    </div>
                </div>
                <table className="table table-responsive" id="tabelPeng">
                    <tbody>
                        {
                            this.state.peng.map(peng => {

                                const showHdn = () => {
                                    if (peng.id_hidden === '1') {
                                        return (
                                            <div>
                                                <button onClick={() => this.hdnChange(peng)} className="btn btn-xs btn-warning" style={{ width: '70px', marginLeft: '10px' }} id={"btnhdn" + peng.id}>
                                                    hidden
                                                </button>
                                                &nbsp;
                                                <button onClick={() => this.hpsChange(peng)} className="btn btn-xs btn-danger" style={{ width: '70px' }} id={"btnhps" + peng.id}>
                                                    hapus
                                                </button>
                                            </div>
                                            // <Link to={'/pgDashedit' + peng.id} className="btn btn-xs btn-info" style={{ marginLeft: '10px', width: '70px' }}>
                                            //     edit
                                            // </Link>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <button onClick={() => this.shwChange(peng)} className="btn btn-xs btn-primary" style={{ width: '70px', marginLeft: '10px' }}
                                                    id={"btnshw" + peng.id}
                                                >
                                                    publish
                                                </button>
                                                &nbsp;
                                                <button onClick={() => this.hpsChange(peng)} className="btn btn-xs btn-danger" style={{ width: '70px' }} id={"btnhps" + peng.id}>
                                                    hapus
                                                </button>
                                            </div>
                                        )
                                    }
                                }

                                var date = new Date(peng.tgl_peng);
                                var tahun = date.getFullYear();
                                var bulan = date.getMonth();
                                var tanggal = date.getDate();
                                switch (bulan) {
                                    case 0: bulan = "January"; break;
                                    case 1: bulan = "February"; break;
                                    case 2: bulan = "March"; break;
                                    case 3: bulan = "April"; break;
                                    case 4: bulan = "May"; break;
                                    case 5: bulan = "June"; break;
                                    case 6: bulan = "July"; break;
                                    case 7: bulan = "August"; break;
                                    case 8: bulan = "September"; break;
                                    case 9: bulan = "October"; break;
                                    case 10: bulan = "November"; break;
                                    case 11: bulan = "December"; break;
                                    default:
                                }
                                var tgl_pengum = tanggal + " " + bulan + " " + tahun;
                                return (
                                    <tr key={peng.id}>
                                        <td>
                                            <Link to={'/pgDashedit' + peng.id} className="linkCustomku">
                                                <h6>{peng.judul_peng}</h6>
                                                <p>{tgl_pengum}</p>
                                            </Link>
                                            <div className="row">
                                                {showHdn()}
                                            </div>
                                        </td>
                                        <td>
                                            <img src={lkgbr.pengLk + peng.gbr_peng + Keykui.keyKui}
                                                className="gbrpeng" alt="" />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

export default PengTable;