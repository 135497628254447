import React, { Component } from 'react';
import API_ASTOR from '../../services/api_astor';
import keyastor from '../../services/key_astor';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

const recaptchaLoaded = () => {
    console.log('captcha successfully loaded');
}

class LoginPage extends Component {

    constructor() {
        super()
        this.state = {
            user_id: '',
            user_name: '',
            user_password: '',
            filedatas: '',
            idus: '',
            dumming: false,
            isVerified: true
        }
        this.inChange = this.inChange.bind(this)
        // this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    recaptchaLoaded = () => {
        console.log('captcha successfully loaded');
    }
    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
        console.log(response);
    }

    inChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleLog = async (event) => {
        if (this.state.isVerified) {
            event.preventDefault()
            let btnpengq = document.getElementById("signin");
            btnpengq.setAttribute("disabled", true);
            document.getElementById("tagnamabtn").innerHTML = 'login &nbsp;<i class="fa fa-spinner fa-spin"></i>'

            await API_ASTOR.post('kui/login/ul' + keyastor.baseURL, this.state)
                .then(Response =>
                    this.setState({
                        filedatas: Response.data[0].filedatas,
                        idus: Response.data[0].idus
                    })
                )
            this.LoginCheck()
        } else {
            alert('Silahkan isi captcha dahulu')
            return false
        }
    }

    LoginCheck() {
        if (this.state.filedatas == 1) {
            const ar = [this.state]
            localStorage.setItem('ad', JSON.stringify(ar))
            this.props.history.push('/loading/' + this.state.idus)
        } else {
            setTimeout(() => {
                swal({
                    icon: 'warning',
                    title: 'Gagal Login',
                    text: 'Username atau Password Salah',
                    button: false
                });
                window.location.reload();
            }, 2000)
        }
    }

    perintah = () => {
        var perintah = document.createElement("script");
        perintah.src = "assets/perintah.js";
        document.getElementsByTagName("head")[0].appendChild(perintah);
    };

    componentDidMount() {
        if (localStorage.getItem("bahasaPilihan") === "english") {
            this.perintah();
        }
        if (sessionStorage.getItem("un") == null) {
            sessionStorage.clear()
            localStorage.clear()
        } else {
            this.props.history.push('/loading/' + sessionStorage.getItem("un"))
        }
        setTimeout(() => {
            this.setState({
                dumming: true
            })
        }, 2000);
    }
    render() {
        return (
            <div>
                <div className="row">
                    <Link to="/" style={{ fontWeight: 'bold', color: '#1e2a57', fontSize: '20px', padding: '30px 0px 0px 50px' }} className="">
                        <i className="fas fa-arrow-circle-left"></i>&nbsp;&nbsp;back to home
                    </Link>
                </div>
                <div className="container2 centerPost">
                    <div className="signin-content">
                        <div className="signin-image">
                            <figure><img src="images/signin-image.jpg" alt="sing up image" /></figure>
                        </div>
                        <div className="signin-form" style={{ marginTop: '-35px' }}>
                            <h2 className="form-title2 titleq">Sign <span style={{ color: '#f05d5d' }}>In</span></h2>
                            <div className="form-group2">
                                <label htmlFor="your_name" className="labelq"><i className="zmdi zmdi-account material-icons-name" /></label>
                                <input type="text" name="user_name"
                                    onChange={this.inChange}
                                    id="your_name" placeholder="Your Name" className="inputq" />
                            </div>
                            <div className="form-group2">
                                <label htmlFor="your_pass" className="labelq"><i className="zmdi zmdi-lock" /></label>
                                <input type="password" name="user_password"
                                    onChange={this.inChange}
                                    id="your_pass" placeholder="Password" className="inputq"
                                />
                            </div>
                            <div className="validate-form">
                                <center>
                                    {this.state.dumming && (
                                        <Recaptcha
                                            sitekey="6Ld_2z4bAAAAAHAb9xj4vvQP3C2Covv-eMuXDEmp"
                                            render="explicit"
                                            onloadCallback={this.recaptchaLoaded}
                                            verifyCallback={this.verifyCallback}
                                        />
                                    )}

                                </center>
                            </div>
                            <div className="form-group2 form-button">
                                <button onClick={this.handleLog} id="signin" className="form-submit">
                                    <span id="tagnamabtn">
                                        Log In
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default LoginPage;