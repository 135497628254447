import React, { Component } from 'react';
import HeadDash from '../templates/headDash';
import NavDash from '../templates/navDash';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import CKEditor from 'ckeditor4-react';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import swal from 'sweetalert';
import linkgbr from '../../../services/linkgbr';

class EditArt extends Component {

    jsq = () => {
        var jsq = document.createElement("script");
        jsq.src = "assets/dashboard.js";
        document.getElementsByTagName("head")[0].appendChild(jsq);
    }

    componentDidMount = () => {
        this.jsq();
        setTimeout(() => {
            let id = this.props.match.params.artId;
            API.get(`artikel/${id}` + Keykui.keyKui,
                {
                    mode: "no-cors",
                    method: "GET"
                }
            ).then(res => {
                let formArt = res.data;
                this.setState({
                    id: formArt.data.id,
                    gbr_berita2: linkgbr.brtLk + formArt.data.gbr_berita + Keykui.keyKui,
                    judul_berita: formArt.data.judul_berita,
                    tgl_berita: formArt.data.tgl_berita,
                    isi_berita: formArt.data.isi_berita,
                    id_hidden: formArt.data.id_hidden,
                    level_id: formArt.data.level_id
                })
            })
        }, 500)
        this.timer = setInterval(() => this.cek(), 1000);
    };
    cek() {
        if (sessionStorage.getItem("un") == null) {
            this.props.history.push('/loadingout')
        } else if (sessionStorage.getItem("un") == undefined) {
            this.props.history.push('/loadingout')
        }
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    state = {
        gbr_berita: null,
        judul_berita: "",
        tgl_berita: "",
        isi_berita: "",
        id_hidden: "",
        level_id: "",
    }

    // ..tgl onChange
    tglChange = (event, selectedDates) => {
        let tgl_artNew = { ...this.state.tgl_berita };
        tgl_artNew = selectedDates;
        this.setState({
            tgl_berita: tgl_artNew
        })
    }

    ckChange = (e, editor) => {
        const data = e.editor.getData();
        this.setState({
            isi_berita: data
        })
    }

    // input berubah 
    artChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // file change
    fcg = (event) => {
        document.getElementById('art3').src = URL.createObjectURL(event.target.files[0]);
        this.setState({
            gbr_berita: event.target.files[0]
        })

        let fi = document.getElementById('gbr');
        let filemui = document.getElementById('gbr');

        if (/(\.jpg|\.jpeg|\.png)$/i.test(filemui.files[0].name) === false) {
            document.getElementById('gbr').value = '';
            document.getElementById('art3').src = linkgbr.brtLk + this.state.gbr_berita2 + Keykui.keyKui;
            swal({
                icon: 'info',
                title: 'Form Gambar',
                text: 'File harus berupa gambar'
            })
            this.setState({
                gbr_berita: null
            })
            return false;
        } else {
            if (fi.files.length > 0) {
                for (var i = 0; i <= fi.files.length - 1; i++) {
                    var fsize = fi.files.item(i).size;
                    const file = Math.round((fsize / 1024));
                    if (file > 1024) {
                        document.getElementById('gbr').value = '';
                        document.getElementById('art3').src = linkgbr.brtLk + this.state.gbr_berita2 + Keykui.keyKui;
                        swal({
                            icon: 'info',
                            title: 'Form Gambar',
                            text: 'File harus kurang dari 1mb'
                        })
                        this.setState({
                            gbr_berita: null
                        })
                        return false;
                    } else {
                        return true
                    }
                }
            }
        }
    }

    handleEditArt = async () => {

        let btnpengq = document.getElementById("btnarted");
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'loading &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        document.getElementById("gbr").setAttribute("disabled", true);
        document.getElementById("judul").setAttribute("disabled", true);
        document.getElementById("tgl").setAttribute("disabled", true);

        await API.put('edit/artikel/' + this.state.id + Keykui.keyKui, this.state,
            {
                mode: 'no-cors',
                method: "PUT"
            }
        )
            .then((res) => {
                this.setState({
                    status: res.data.status,
                    idGbr: res.data.id
                })
            })
        if (this.state.status == 1) {
            if (this.state.gbr_berita != null) {
                // alert("berarti gambar tidak null")
                const formData = new FormData();

                formData.append(
                    "gbr_berita",
                    this.state.gbr_berita,
                    this.state.gbr_berita.name,
                    this.state
                );
                await API.post('upld/artEdit/add' + Keykui.keyKui + '&id=' + this.state.idGbr, formData,
                    {
                        mode: 'no-cors',
                        method: "POST",
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(() => {
                    swal({
                        title: 'Edit berhasil',
                        text: 'menutup jendela...',
                        icon: 'success',
                        timer: 3000,
                        buttons: false,
                    }).then(function () {
                        window.location.replace('/artDash')
                    })
                })
            } else {
                // alert("berarti gambar null")
                swal({
                    title: 'Edit berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location.replace('/artDash')
                })
            }
        }
    }

    render() {
        function bukaTtg3() {
            document.getElementById("ttgkui3").classList.remove("ilang");
            document.getElementById("ttgkui3").classList.add("modal");
        }
        function tutupTtg3() {
            document.getElementById("ttgkui3").classList.remove("modal");
            document.getElementById("ttgkui3").classList.add("ilang");
        }
        return (
            <div>
                <div className="wrapper">
                    <NavDash />
                    <div id="content">
                        <HeadDash />
                        <div className="container-fluid custisi">
                            <div className="row">
                                <Link to="/artDash" style={{ fontWeight: 'bold', color: '#1e2a57', fontSize: '20px' }} className="none-hp">
                                    <i className="fas fa-arrow-circle-left"></i>&nbsp;&nbsp;kembali
                                </Link>
                                <h2 className="titlemu" style={{ fontWeight: 'bold', color: '#1e2a57' }}>Add Artikel KUI</h2>
                            </div>
                            <div className="row"
                                style={{
                                    marginTop: '20px', backgroundColor: 'white', padding: '20px',
                                    borderRadius: '20px'
                                }}>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="judul">Judul Artikel</label>
                                        <textarea
                                            defaultValue={this.state.judul_berita}
                                            type="text" name="judul_berita" className="form-control" onChange={this.artChange}
                                            id="judul"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="file">Foto Headline Utama</label>
                                        <input type="file" name="gbr_berita" className="form-control"
                                            onChange={this.fcg}
                                            id="gbr"
                                        />
                                    </div>
                                    <button onClick={bukaTtg3} className="btn btn-secondary">
                                        Lihat gambar
                                    </button>
                                    {/* modal gbr artikel */}
                                    <div id="ttgkui3" className="ilang">
                                        <div className="modal-content2">
                                            <button onClick={tutupTtg3} className="close" style={{ color: '#000' }}>
                                                ×
                                            </button>
                                            <br />
                                            <img src={this.state.gbr_berita2} alt="gambar belum dipilih" className="formgbrpeng" id="art3" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 spa">
                                    <div className="form-group">
                                        <label htmlFor="judul">Tanggal Artikel</label>
                                        <Flatpickr
                                            className="form-control"
                                            value={this.state.tgl_berita}
                                            onChange={this.tglChange}
                                            id="tgl"
                                        />
                                    </div>
                                    <br />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="form-group">
                                        <label htmlFor="isi">Isi Artikel</label>
                                        <CKEditor
                                            data={this.state.isi_berita}
                                            onChange={this.ckChange}
                                        />
                                    </div>
                                    <br />
                                    <button onClick={this.handleEditArt} className="btn btn-success" style={{ float: 'right', color: 'white' }}
                                        id="btnarted"
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditArt;