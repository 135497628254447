import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../services/api';
import Keykui from '../../../services/key';
import lkgbr from '../../../services/linkgbr';
import swal from 'sweetalert';

class ArtTable extends Component {
    // state 
    state = {
        art: []
    }

    hdnChange = (art) => {
        swal({
            title: "Yakin untuk di hidden?",
            text: "artikel tidak akan terlihat di tampilan utama!",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((baikdiubah) => {
            if (baikdiubah) {
                let btnpengq = document.getElementById("btnhdn" + art.id);
                btnpengq.setAttribute("disabled", true);
                btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'
                API.put('hdn/artikel/' + art.id + Keykui.keyKui,
                    {
                        mode: "no-cors",
                        method: "PUT"
                    }
                )
                    .then(() => {
                        swal({
                            title: 'Hidden berhasil',
                            text: 'menutup jendela...',
                            icon: 'success',
                            timer: 3000,
                            buttons: false,
                        }).then(function () {
                            window.location = "/artDash";
                        })
                    })
            } else {

            }
        })
    }

    hpsChange = (art) => {
        swal({
            title: "Yakin untuk di hapus ?",
            text: "artikel akan terhapus permanen !",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((baikdiubah) => {
            if (baikdiubah) {
                let btnpengq = document.getElementById("btnhps" + art.id);
                btnpengq.setAttribute("disabled", true);
                btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'
                API.delete('artHps/artikel/add/' + Keykui.keyKui + '&id=' + art.id,
                    {
                        mode: "no-cors",
                        method: "POST"
                    }
                )
                    .then((res) => {
                        swal({
                            title: 'Hapus berhasil',
                            text: 'menutup jendela...',
                            icon: 'success',
                            timer: 3000,
                            buttons: false,
                        }).then(function () {
                            window.location = "/artDash";
                        })
                    })
            }
        })
    }

    shwChange = (art) => {
        let btnpengq = document.getElementById("btnshw" + art.id);
        btnpengq.setAttribute("disabled", true);
        btnpengq.innerHTML = 'wait &nbsp;<i class="fa fa-spinner fa-spin"></i>'

        API.put('shw/artikel/' + art.id + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "PUT"
            }
        )
            .then(() => {
                swal({
                    title: 'Publish berhasil',
                    text: 'menutup jendela...',
                    icon: 'success',
                    timer: 3000,
                    buttons: false,
                }).then(function () {
                    window.location = "/artDash";
                })
            })
    }

    //get pengumuman
    async artGet() {
        await API.get('tampil/artikel' + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            this.setState({
                art: res.data,
                status: res.status
            })
        })
        if (this.state.status === 200) {
            document.querySelector(".bodyqqqk").classList.add("sembunyi")
            setTimeout(() => {
                var tablemu = document.createElement("script");
                tablemu.src = "assets/tableCust.js";
                document.getElementsByTagName("head")[0].appendChild(tablemu);
            }, 500)
        }
    }

    componentDidMount = () => {
        this.artGet();
    }

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <div>
                <div className="bodyqqqk" style={{padding: '50px'}}>
                    <div className="containerqq">
                        <div className="yellow bullet"></div>
                        <div className="red bullet"></div>
                        <div className="blue bullet"></div>
                        <div className="violet bullet"></div>
                    </div>
                </div>
                <table className="table table-responsive" id="tabelArt">
                    <tbody>
                        {

                            this.state.art.map(art => {

                                const showHdn = () => {
                                    if (art.id_hidden === '1') {
                                        return (
                                            <div>
                                                <div>
                                                    <button onClick={() => this.hdnChange(art)} className="btn btn-xs btn-warning" style={{ width: '70px', marginLeft: '10px' }} id={"btnhdn" + art.id}>
                                                        hidden
                                                    </button>
                                                    &nbsp;
                                                    <button onClick={() => this.hpsChange(art)} className="btn btn-xs btn-danger" style={{ width: '70px' }} id={"btnhps" + art.id}>
                                                        hapus
                                                    </button>
                                                </div>
                                            </div>
                                            // <Link to={'/artDashedit' + art.id} className="btn btn-xs btn-info" style={{ marginLeft: '10px', width: '70px' }}>
                                            //     edit
                                            // </Link>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <button onClick={() => this.shwChange(art)} className="btn btn-xs btn-primary" style={{ width: '70px', marginLeft: '10px' }}
                                                    id={"btnshw" + art.id}
                                                >
                                                    publish
                                                </button>
                                                &nbsp;
                                                <button onClick={() => this.hpsChange(art)} className="btn btn-xs btn-danger" style={{ width: '70px' }} id={"btnhps" + art.id}>
                                                    hapus
                                                </button>
                                            </div>
                                        )
                                    }
                                }

                                var date = new Date(art.tgl_berita);
                                var tahun = date.getFullYear();
                                var bulan = date.getMonth();
                                var tanggal = date.getDate();
                                switch (bulan) {
                                    case 0: bulan = "January"; break;
                                    case 1: bulan = "February"; break;
                                    case 2: bulan = "March"; break;
                                    case 3: bulan = "April"; break;
                                    case 4: bulan = "May"; break;
                                    case 5: bulan = "June"; break;
                                    case 6: bulan = "July"; break;
                                    case 7: bulan = "August"; break;
                                    case 8: bulan = "September"; break;
                                    case 9: bulan = "October"; break;
                                    case 10: bulan = "November"; break;
                                    case 11: bulan = "December"; break;
                                    default:
                                }
                                var tgl_pengum = tanggal + " " + bulan + " " + tahun;
                                return <tr key={art.id}>
                                    <td>
                                        <Link to={'/artDashedit' + art.id} className="linkCustomku">
                                            <h6>{art.judul_berita}</h6>
                                            <p>{tgl_pengum}</p>
                                        </Link>
                                        <div className="row">
                                            {showHdn()}
                                        </div>
                                    </td>
                                    <td>
                                        <img src={lkgbr.brtLk + art.gbr_berita + Keykui.keyKui}
                                            className="gbrpeng" alt="" />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ArtTable;