import React, { Component, Fragment } from "react";
import Header from '../../components/header';
import Footer from '../../components/footer';

class Program extends Component {
  perintah = () => {
    var perintah = document.createElement("script");
    perintah.src = "assets/perintah.js";
    document.getElementsByTagName("head")[0].appendChild(perintah);
  };

  componentDidMount = () => {
    if (localStorage.getItem("bahasaPilihan") === "english") {
      this.perintah();
    }
    // navbar
    window.onscroll = function () { scrollFunction() };
    var myNav = document.getElementById('header');

    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        myNav.classList.remove("nav-transparent");
        myNav.classList.add("nav-berwarna");
      } else {
        myNav.classList.remove("nav-berwarna");
        myNav.classList.add("nav-transparent");
      }
    }

    // scroll nav dan for val
    var scriptku = document.createElement("script");
    scriptku.src = "assets/jscustom.js";
    document.getElementsByTagName("head")[0].appendChild(scriptku);
  }

  render() {
    window.scrollTo(0, 0);
    function ubahbtn2() {
      document.getElementById("btn1ku").classList.remove("btn-customku6");
      document.getElementById("btn1ku").classList.add("hilang");
      document.getElementById("btn11ku").classList.remove("btn-customku6");
      document.getElementById("btn11ku").classList.add("hilang");
    }
    function ubahbtn3() {
      document.getElementById("btn2ku").classList.remove("btn-customku6");
      document.getElementById("btn2ku").classList.add("hilang");
      document.getElementById("btn22ku").classList.remove("btn-customku6");
      document.getElementById("btn22ku").classList.add("hilang");
    }
    function ubahbtn4() {
      document.getElementById("btn3ku").classList.remove("btn-customku6");
      document.getElementById("btn3ku").classList.add("hilang");
      document.getElementById("btn33ku").classList.remove("btn-customku6");
      document.getElementById("btn33ku").classList.add("hilang");
    }
    return (
      <Fragment>
        <Header />
        <div className="gap-program"></div>
        <div className="container">
          <div className="jumbotron programku">
            <h3 className="programsku indo">
              Program Biro Kerjasama
            </h3>
            <h3 className="programsku english hide">
              Cooperative Bureau Program
            </h3>
            <div className="col-md-12">
              <p className="judulpend">
                <span className="indo">1. Pendaftaran Mahasiswa Asing</span>
                <span className="english hide">1.	Student International Admission</span>
              </p>
              <p className="isi pend">
                <span className="indo">
                  Institut Teknologi dan Bisnis Asia Malang menerima pendaftaran
                  dari calon mahasiswa yang berasal dari Luar negeri.
                  Calon mahasiswa dapat memilih semua program studi yang
                  ditawarkan di Institut Teknologi dan Bisnis Asia Malang
                  sesuai dengan syarat yang telah ditentukan.
                  Perkuliahan akan dilaksanakan dalam Bahasa Indonesia dan
                  Bahasa Inggris untuk mata kuliah tertentu.
                </span>
                <span className="english hide">
                  Institut Teknologi dan Bisnis Asia Malang  opens for International students
                  who want to join in Institut Teknologi dan Bisnis Asia Malang. Prospective
                  students can take all the study programs offered at the Institut Teknologi dan Bisnis Asia Malang
                  according to predetermined conditions. Lectures will be conducted in Indonesian and English for certain subjects.
                </span>
              </p>
              <div>
                <a className="bacapro indo" onClick={ubahbtn2} id="btn1ku" data-toggle="collapse"
                  href="#collapseExample1">
                  baca selengkapnya <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
                <a className="bacapro english hide" onClick={ubahbtn2} id="btn11ku" data-toggle="collapse"
                  href="#collapseExample1">
                  read more <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
              </div>
              <div className="collapse row" id="collapseExample1" style={{ marginTop: '-30px' }}>
                <div className="container">
                  <p className="judulpend">
                    <span className="indo">
                      Alur Proses Pengajuan Mahasiswa Asing KUI :
                    </span>
                    <span className="english hide">
                      Admissions process
                    </span>
                  </p>
                  <div className="indo">
                    1. Pendaftaran Mahasiswa Asing
                    <br />
                    <ul>
                      <li>a. Mahasiswa daftar melalui online</li>
                      <li>b. Melengkapi dokumen persyaratan pendaftaran</li>
                      <li>c. Mahasiswa diterima, kampus menerbitkan LOA (Letter Of Acceptance) PIC Rektor</li>
                    </ul>
                    2. Proses Pengajuan Surat Ijin Belajar (SIB) Baru
                    <br />
                    <ul>
                      <li>a. Mahasiswa mendatangi KUI</li>
                      <li>b. Melengkapi dokumen persyaratan pengajuan SIB :
                        <ol>
                          a). Foto terbaru (Jpg/Png)
                        </ol>
                        <ol>
                          b). Scan Paspor
                        </ol>
                        <ol>
                          c). Surat Jaminan Keuangan Bermaterai 6000
                        </ol>
                        <ol>
                          d). Surat Pernyataan bermaterai 6000 :
                          <dl>
                            &nbsp;&nbsp;- Bersedia mematuhi peraturan dan UU yang berlaku di RI
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Tidak bekerja selama di Indonesia
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Tidak mengajukan beasiswa di Indonesia
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Mematuhi peraturan kampus
                          </dl>
                        </ol>
                        <ol>
                          e). Surat Keterangan sehat dari dokter
                        </ol>
                        <ol>
                          f). Fotocopy Ijasah Legalisir
                        </ol>
                        <ol>
                          g). LOA (Letter Of Acceptance)
                        </ol>
                        <ol>
                          h). Surat Rekomendasi dari kampus PIC WR I
                        </ol>
                      </li>
                    </ul>
                    3.  Pengurusan Visa Online
                    <br />
                    <ul>
                      <li>a. Mahasiswa asing melakukan pendaftaran secara online melalui laman
                        <br />
                        <a href="https://visa-online.imigrasi.go.id/register/index.xhtml" target="blank">
                          visaonline.imigrasi.go.id
                        </a>
                      </li>
                      <li>
                        b. Mahasiswa melengkapi persyaratan pengurusan visa online :
                        <ol>
                          a). Buku tabungan orangtua mahasiswa Min 1900 US Dolar depan dan dalam
                        </ol>
                        <ol>
                          b). Surat Ijin Belajar
                        </ol>
                        <ol>
                          c). Surat Jaminan Sponsor PIC Wakil Rektor IV bidang Kerjasama
                        </ol>
                        <ol>
                          d). Surat Permohonan dan Jaminan mendatangkan mahasiswa asing PIC WR I
                        </ol>
                        <ol>
                          e). Sampul Paspor depan dan dalam
                        </ol>
                      </li>
                    </ul>
                    4. Pengurusan KITAS
                    <br />
                    <ul>
                      <li>a. Mahasiswa asing melakukan pendaftaran secara online melalui laman :
                        <br />
                        <a href="https://izintinggal-online.imigrasi.go.id/" target="blank">
                          izintinggal.imigrasi.go.id
                        </a>
                        <br />
                        (Menunggu mahasiswa tiba di Indonesia)
                      </li>
                      <li>
                        b. Mahasiswa melengkapi persyaratan pengurusan KITAS :
                        <ol>
                          a). Surat Permohonan KITAS
                        </ol>
                        <ol>
                          b). Surat Jaminan Sponsor
                        </ol>
                        <ol>
                          c). Copy E-KTP Penjamin
                        </ol>
                        <ol>
                          d). Copy Paspor, Visa dan Cap Pendaratan
                        </ol>
                        <ol>
                          e). Teleks Visa / Pemberitahuan persetujuan visa (Apabila mengurus visa online)
                        </ol>
                        <ol>
                          f). Print out ijin belajar
                        </ol>
                      </li>
                    </ul>
                    5. Proses Pengajuan Surat Ijin Belajar Perpanjangan (SIB)
                    <br />
                    <ul>
                      <li>
                        a. Mahasiswa mendatangi KUI
                      </li>
                      <li>
                        b. Melengkapi dokumen persyaratan pengajuan SIB :
                        <ol>
                          a). Foto terbaru (Jpg/Png)
                        </ol>
                        <ol>
                          b). Scan Paspor
                        </ol>
                        <ol>
                          c). Surat Jaminan Keuangan Bermaterai 6000
                        </ol>
                        <ol>
                          d). Surat Pernyataan bermaterai 6000 :
                          <dl>
                            &nbsp;&nbsp;- Bersedia mematuhi peraturan dan UU yang berlaku di RI
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Tidak bekerja selama di Indonesia
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Tidak mengajukan beasiswa di Indonesia
                          </dl>
                          <dl>
                            &nbsp;&nbsp;- Mematuhi peraturan kampus
                          </dl>
                        </ol>
                        <ol>
                          e). Surat Keterangan sehat dari dokter
                        </ol>
                        <ol>
                          f). Fotocopy Ijasah Legalisir
                        </ol>
                        <ol>
                          g). Surat Keterangan Aktif Kuliah dari kampus PIC WR I
                        </ol>
                        <ol>
                          h). Surat Rekomendasi dari kampus PIC WR I
                        </ol>
                        <ol>
                          i). KITAS
                        </ol>
                        <ol>
                          j).     SKLD (Surat Tanda Pelapor Diri) dari kepolisian
                        </ol>
                      </li>
                    </ul>
                    Note :
                    <br />
                    <ul>
                      <li>
                        1. Nama mahasiswa bersangkutan tidak boleh disingkat (berdasarkan nama di passport)
                      </li>
                      <li>
                        2. Alamat asal dan tinggal di malang lengkap (Jalan, kota, provinsi, negara, dan kode pos)
                      </li>
                      <li>
                        3. Wajib menyertakan email dan No. Telepon
                      </li>
                      <li>
                        4. Fotocopy KTM
                      </li>
                      <li>
                        5. Pengurusan Visa Online
                        <ol>
                          a. Mahasiswa asing melakukan pendaftaran secara online melalui laman &nbsp;
                          <a target="blank" href="https://visa-online.imigrasi.go.id/register/index.xhtml">visaonline.imigrasi.go.id</a>
                        </ol>
                        <ol>
                          b. Mahasiswa melengkapi persyaratan pengurusan visa online :
                          <dl>
                            a). Buku tabungan orangtua mahasiswa Min 1900 US Dolar depan dan dalam
                          </dl>
                          <dl>
                            b). Surat Ijin Belajar
                          </dl>
                          <dl>
                            c). Surat Jaminan Sponsor PIC Rektor
                          </dl>
                          <dl>
                            d). Surat Permohonan dan Jaminan mendatangkan mahasiswa asing PIC WR I
                          </dl>
                          <dl>
                            e). Sampul Paspor depan dan dalam
                          </dl>
                        </ol>
                      </li>
                    </ul>
                  </div>
                  <div className="english hide">
                    1. Online registration via <a href="https://pendaftaranonline.asia.ac.id/" target="blank">pendaftaranonline.asia.ac.id</a>
                    <br />
                    <br />
                    2. Completing all documents required
                    <br />
                    <br />
                    3. LOA issued by Institut Teknologi dan Bisnis Asia Malang
                    <br />
                    <br />
                    4. Apply for Surat Ijin Belajar (SIB)
                    <ul style={{ marginTop: '5px' }}>
                      <li>
                        a. 4 passport-sized photograph.(red background)
                      </li>
                      <li>
                        b. Curriculum Vitae
                      </li>
                      <li>
                        c. Copy of Passport
                      </li>
                      <li>
                        d. Statement of financial support
                      </li>
                      <li>
                        e. Personal statement (not to work during the study)
                      </li>
                      <li>
                        f. Recommendation letter from applicants institution
                      </li>
                      <li>
                        g. Recommendation letter from Indonesian Embassy in applicants’ country
                      </li>
                      <li>
                        h. Copy of academic record
                      </li>
                      <li>
                        i. Statement of Good Health
                      </li>
                    </ul>
                    5.	Apply for telex visa via <a target="blank" href="https://visa-online.imigrasi.go.id/register/index.xhtml">visaonline.imigrasi.go.id</a> and Completing all documents required such as
                    <br />
                    <ul style={{ marginTop: '10px' }}>
                      <li>a. A copy of  passport</li>
                      <li>b. A copy of the study permit from the Ministry of National Education</li>
                      <li>c. A copy of the Recommendation Letter from your university addressed to the Directorate of Immigration.</li>
                      <li>d. A copy of the Letter of Acceptance Letter from your university</li>
                      <li>e. A copy of your academic transcript and records</li>
                      <li>f. Your Curriculum Vitae</li>
                      <li>g. Two recent passport-size pictures (4×6 cm)</li>
                      <li>h. A Letter of Financial Guarantee or a sponsorship certificate which proves you can cover your study and living expenses.</li>
                      <li>i. Health certificate</li>
                    </ul>
                    6. Applying VITAS and KITAS
                    <br />
                    <ul>
                      <li>The documents required to submit for a Student Visa Application for Indonesia include :
                        <ol>
                          a. A copy of passport
                        </ol>
                        <ol>
                          b. A copy of the study permit from the Ministry of National Education
                        </ol>
                        <ol>
                          c. A copy of the Recommendation Letter from your university addressed to the Directorate of Immigration.
                        </ol>
                        <ol>
                          d. A copy of the Letter of Acceptance Letter from your university
                        </ol>
                        <ol>
                          e. A copy of your academic transcript and records
                        </ol>
                        <ol>
                          f. Your Curriculum Vitae
                        </ol>
                        <ol>
                          g. Two recent passport-size pictures (4×6 cm)
                        </ol>
                        <ol>
                          h. A Letter of Financial Guarantee or a sponsorship certificate which proves you can cover your study and living expenses
                        </ol>
                        <ol>
                          i.	Health certificate
                        </ol>
                      </li>
                      <li>
                        Documents required for an Indonesia Student KITAS :
                        <ol>
                          a.	Your passport
                        </ol>
                        <ol>
                          b.	The Letter of Acceptance
                        </ol>
                        <ol>
                          c.	The Letter of Recommendation or the Certificate of Sponsorship from the university
                        </ol>
                        <ol>
                          d.	A copy of of the telex visa
                        </ol>
                        <ol>
                          e.	A copy of of the study permit
                        </ol>
                        <ol>
                          f.	Your CV
                        </ol>
                        <ol>
                          g.	Your academic reports
                        </ol>
                        <ol>
                          h.	Proof of financial responsibility from your parents, legal guardians, or a sponsor during your studies
                        </ol>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12" style={{ marginTop: '30px' }}>
              <p className="judulpend">
                <span className="indo">2. Pendaftaran Magang Luar Negeri </span>
                <span className="english hide">2. Internship Program</span>
              </p>
              <p>
                <span className="indo">
                  Program magang ke Luar negeri adalah salah satu program KUI
                  Institut Teknologi dan Bisnis Asia Malang yang dilakukan selama
                  1 sampai 3 bulan di negara-negara mitra ASEAN dibawah naungan SEAMEO.
                  Mahasiswa diberikan kesempatan untuk mempraktekkan  ilmu yang didapatkan
                  selama Perkuliahan di dunia kerja internasional.
                </span>
                <span className="english hide">
                  The Internship Program is an internship program at a university that collaborates with Institut Asia Malang. Students will have an internship for 1 month or more abroad in accordance with their respective study programs.
                  SEA-TVET Internship Exchange Programme
                </span>
              </p>
              <div>
                <a className="bacapro indo" onClick={ubahbtn3} id="btn2ku" data-toggle="collapse"
                  href="#collapseExample2">
                  baca selengkapnya <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
                <a className="bacapro english hide" onClick={ubahbtn3} id="btn22ku" data-toggle="collapse"
                  href="#collapseExample2">
                  read more <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
              </div>
              <div className="collapse row" id="collapseExample2" style={{ marginTop: '-30px' }}>
                <div className="container">
                  <p className="judulpend">
                    <span className="indo">
                      A. Syarat Pendaftaran Mahasiswa Magang internasional :
                    </span>
                    <span className="english hide">
                      A. Important Requirements about Students :
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span className="indo">
                        1.	Aktif sebagai mahasiswa Institut Teknologi dan Bisnis Asia Malang
                      </span>
                      <span className="english hide">
                        1.	Student of Institut Teknologi dan Bisnis Asia Malang
                      </span>
                    </li>
                    <li>
                      <span className="indo">2.	Usia minimal 18 tahun</span>
                      <span className="english hide">2.	18 years old (minimal)</span>
                    </li>
                    <li>
                      <span className="indo">3.	Menguasai Bahasa Inggris baik aktif maupun pasif</span>
                      <span className="english hide">3.	Be able to communicate in English well</span>
                    </li>
                    <li>
                      <span className="indo">4.	Kemampuan akademik dibuktikan dengan transkrip dan minimal  IPK 3.0</span>
                      <span className="english hide">4.	Have good academic performance and technical skills in the area of major study (GPA 3.0 minimal)</span>
                    </li>
                    <li>
                      <span className="indo">5.	Berkelakuan baik, mudah beradaptasi, dewasa, sopan, berkemauan kuat</span>
                      <span className="english hide">5.	Good attitude, friendly, flexible, good problem-solving, Maturity, and readiness</span>
                    </li>
                    <li>
                      <span className="indo">6.	Mampu secara finansial</span>
                      <span className="english hide">6.	Have enough financial support from parents</span>
                    </li>
                    <li>
                      <span className="indo">7.	Kesehatan (dibuktikan dengan surat kesehatan)</span>
                      <span className="english hide">7.	Good health (no disease or personal health disorder)</span>
                    </li>
                    <li>
                      <span className="indo">8.	Bersedia mengikuti jadwal magang yang telah ditentukan</span>
                      <span className="english hide">8.	Be able to travel abroad during the internship schedule</span>
                    </li>
                  </ul>
                  <p className="judulpend">
                    <span className="indo">
                      B.  Dokumen yang dibutuhkan :
                    </span>
                    <span className="english hide">
                      B. Document Requirement for Students :
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span className="indo">
                        1.	Form Profil mahasiswa dan Transkrip
                      </span>
                      <span className="english hide">
                        1.	Student profile and transcript
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        2.	Paspor aktif
                      </span>
                      <span className="english hide">
                        2.	Valid passport longer than 8 months
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        3.	Visa (Untuk yang magang lebih dari 30 hari)
                      </span>
                      <span className="english hide">
                        3.	Visa (For the student who will have exchange for over 30 days)
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        4.	Surat kesehatan
                      </span>
                      <span className="english hide">
                        4.	Health certification
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        5.	Asuransi kesehatan dan perjalanan internasional
                      </span>
                      <span className="english hide">
                        5.	International Health and Travel Insurance
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        6.	Surat ijin orangtua
                      </span>
                      <span className="english hide">
                        6.	Parents consent form
                      </span>
                    </li>
                  </ul>
                  <p className="judulpend">
                    <span className="indo">
                      C.  Alur proses perekrutan :
                    </span>
                    <span className="english hide">
                      C.  Recruitment Process :
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span className="indo">
                        1.	Lolos secara syarat dan dokumen
                      </span>
                      <span className="english hide">
                        1.	Completing all documents required
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        2.	Interview oleh Kantor Urusan Internasional
                      </span>
                      <span className="english hide">
                        2.	Interview (OIA Institut Teknologi dan Bisnis Asia Malang)
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        3.	Interview oleh Kampus penerima
                      </span>
                      <span className="english hide">
                        3.	Interview (Host Institutions)
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        4.	LOA dari kampus penerima
                      </span>
                      <span className="english hide">
                        4.	LOA Issued
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12" style={{ marginTop: '30px' }}>
              <p className="judulpend">
                <span className="indo">3. Kredit Transfer Internasional </span>
                <span className="english hide">3. International Credit Transfer</span>
              </p>
              <p>
                <span className="indo">
                  Program kredit transfer internasional adalah salah satu program di Institut Teknologi
                  dan Bisnis Asia Malang yang mengakomodasi mobilitas mahasiswa Institut Teknologi dan
                  Bisnis Asia Malang yang ingin mengambil kredit semester di kampus Luar Negeri mitra dan untuk mahasiswa asing kampus mitra yang
                  ingin mengambil kredit semester di Institut Teknologi dan Bisnis Asia Malang. Durasi program ini tergantung dari universitas masing-masing.
                </span>
                <span className="english hide">
                  The international transfer credit program is one of the programs at  Institut Teknologi dan Bisnis Asia Malang that accommodates the mobility of students of Institut Teknologi dan Bisnis Asia Malang who wish to take semester credits at foreign campuses and for foreign students who
                  wish to take semester credits at the  Institut Teknologi dan Bisnis Asia Malang. The duration of this program depends on each university.
                </span>
              </p>
              <div>
                <a className="bacapro indo" onClick={ubahbtn4} id="btn3ku" data-toggle="collapse"
                  href="#collapseExample3">
                  baca selengkapnya <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
                <a className="bacapro english hide" onClick={ubahbtn4} id="btn33ku" data-toggle="collapse"
                  href="#collapseExample3">
                  read more <span className="fa fa-arrow-circle-down" style={{ color: ' #f4a832' }} />
                </a>
              </div>
              <div className="collapse row" id="collapseExample3" style={{ marginTop: '-30px' }}>
                <div className="container">
                  <p className="judulpend">
                    <span className="indo">
                      A. Persyaratan :
                    </span>
                    <span className="english hide">
                      A. Requirements:
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span className="indo">
                        1.	Transkrip akademik dalam Bahasa Inggris
                      </span>
                      <span className="english hide">
                        1.	 Official Academic Transcript in English Version
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        2.	Surat Keterangan Mahasiswa Aktif Institut Teknologi dan Bisnis Asia Malang dalam bahasa Inggris
                      </span>
                      <span className="english hide">
                        2.	Certificate of Enrollment from Institut Teknologi dan Bisnis Asia Malang
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        3.	Sertifikasi Kemampuan Bahasa Inggris
                      </span>
                      <span className="english hide">
                        3.	Certificate of English Proficiency
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        4.	Paspor
                      </span>
                      <span className="english hide">
                        4.	Passport
                      </span>
                    </li>
                    <li>
                      <span className="indo">
                        5.	Form kredit transfer dan Persetujuan beban studi yang diambil (disediakan oleh KUI Institut Teknologi dan Bisnis Asia Malang)
                      </span>
                      <span className="english hide">
                        5.	Learning Agreement (provided by OIA Institut Teknologi dan Bisnis Asia Malang)
                      </span>
                    </li>
                    <li>
                      <span className="indo">6.	Surat pernyataan</span>
                      <span className="english hide">6.	Statement Letter </span>
                    </li>
                    <li>
                      <span className="indo">7.	Surat Rekomendasi</span>
                      <span className="english hide">7.	Recommendation Letter in English Version</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '5%' }}></div>
        <Footer />
      </Fragment >
    );
  }
}

export default Program;
