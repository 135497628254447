import React, { Component, Fragment } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import API from '../../services/api';
import lkgbr from '../../services/linkgbr';
import Keykui from '../../services/key';

class DetailPengumuman extends Component {

  state = {
    judulbrt: "",
    tglbrt: "",
    isibrt: ""
  }

  //get berita
  async beritaDetail() {
    let id = this.props.match.params.beritaId;
    await API.get(`artikel/${id}` + Keykui.keyKui,
      {
        mode: "no-cors",
        method: "GET"
      }
    ).then(res => {
      this.setState({
        gbrbrt: lkgbr.brtLk + res.data.data.gbr_berita + Keykui.keyKui,
        judulbrt: res.data.data.judul_berita,
        tglbrt: res.data.data.tgl_berita,
        isibrt: res.data.data.isi_berita,
        status: res.status
      })
    })
    if (this.state.status == 200) {
      document.querySelector(".bodyqqq").classList.add("sembunyi");
      document.querySelector(".detailBrt").classList.remove("sembunyi");
    }
  }

  perintah = () => {
    var perintah = document.createElement("script");
    perintah.src = "assets/perintah.js";
    document.getElementsByTagName("head")[0].appendChild(perintah);
  };

  componentDidMount = () => {
    if (localStorage.getItem("bahasaPilihan") === "english") {
      this.perintah();
    }
    this.beritaDetail();
    // navbar
    window.onscroll = function () { scrollFunction() };
    var myNav = document.getElementById('header');

    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        myNav.classList.remove("nav-transparent");
        myNav.classList.add("nav-berwarna");
      } else {
        myNav.classList.remove("nav-berwarna");
        myNav.classList.add("nav-transparent");
      }
    }

    // scroll nav dan for val
    var scriptku = document.createElement("script");
    scriptku.src = "assets/jscustom.js";
    document.getElementsByTagName("head")[0].appendChild(scriptku);

    // animation 
    var aos12 = document.createElement("script");
    aos12.src = "assets/aos.js";
    document.getElementsByTagName("head")[0].appendChild(aos12);
  }

  render() {
    var date = new Date(this.state.tglbrt);
    var tahun = date.getFullYear();
    var bulan = date.getMonth();
    var tanggal = date.getDate();
    switch (bulan) {
      case 0: bulan = "January"; break;
      case 1: bulan = "February"; break;
      case 2: bulan = "March"; break;
      case 3: bulan = "April"; break;
      case 4: bulan = "May"; break;
      case 5: bulan = "June"; break;
      case 6: bulan = "July"; break;
      case 7: bulan = "August"; break;
      case 8: bulan = "September"; break;
      case 9: bulan = "October"; break;
      case 10: bulan = "November"; break;
      case 11: bulan = "December"; break;
      default:
    }
    var tglberita = tanggal + " " + bulan + " " + tahun;
    return (
      <Fragment>
        <Header />
        <div className="container">
          <div className="jumbotron" style={{ marginTop: '70px', backgroundColor: 'transparent' }}>
            <div className="bodyqqq">
              <div className="containerqq">
                <div className="yellow bullet"></div>
                <div className="red bullet"></div>
                <div className="blue bullet"></div>
                <div className="violet bullet"></div>
              </div>
            </div>
            <div className="detailBrt sembunyi">
              <div className="row">
                <div className="col-md-6" data-aos="fade-right">
                  <img src={this.state.gbrbrt} alt="" style={{ width: '100%', borderRadius: '10px' }} />
                </div>
                <div className="col-md-6">
                  <p className="tulisanp" style={{ marginTop: '5%' }} data-aos="fade-left">
                    {this.state.judulbrt}
                  </p>
                  <p style={{ marginTop: '30px', fontWeight: 'bold', fontSize: '12pt' }} className="tglart" data-aos="fade-left">
                    {tglberita}
                  </p>
                </div>
                <div className="col-md-12" style={{ marginTop: '5%' }}>
                  <div dangerouslySetInnerHTML={{ __html: this.state.isibrt }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default DetailPengumuman;
