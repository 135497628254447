import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeadDash extends Component {
    valLog() {
        // if (localStorage.getItem("logus") == null && localStorage.getItem("nama_adm") == null) {
        //     sessionStorage.clear()
        //     window.location.replace('/loadingout')
        // }

        if (sessionStorage.getItem("un") === null) {
            window.location.replace('/loadingout')
        }

        if (sessionStorage.getItem("un") === undefined) {
            window.location.replace('/loadingout')
        }
    }

    componentDidMount = () => {
        this.valLog();
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn" style={{ backgroundColor: 'transparent' }}>
                            <i className="fas fa-align-left" style={{ color: '#ec3b3b', fontSize: '20px' }} />
                        </button>
                        <button className="btn d-inline-block d-lg-none ml-auto" style={{ backgroundColor: 'transparent', border: 'none' }}
                            type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-user-circle" style={{ color: '#1e2a57', fontSize: '20px' }} />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="nav navbar-nav ml-auto mt-2">
                                {
                                    sessionStorage.getItem("ifDash") == "yes" ? null :
                                        <li className="nav-item">
                                            <Link to="/loadingout" className="nav-link" href="#" style={{ fontSize: '15px', color: '#1e2a57', fontWeight: 'bold' }}>
                                                Logout&nbsp;&nbsp;<i className="fas fa-power-off " style={{ color: '#ec3b3b' }}></i>
                                            </Link>
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default HeadDash;