import React, { Component } from 'react';
import Footer from '../../components/footer';
import API from '../../services/api';
import linkgbr from '../../services/linkgbr';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Header from '../../components/header';
import Keykui from '../../services/key';

class MorePeng extends Component {

    constructor(props) {
        super(props)

        this.state = {
            offset: 0,
            berita: [],
            organizeData: [],
            perPage: 4,
            currentPage: 0
        }
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    //get berita
    async beritaGet() {
        await API.get('dash/tampil/art' + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            var data = res.data;
            var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)

            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                organizeData: res.data,
                berita: slice,
                status: res.status
            })
        })
        if (this.state.status == 200) {
            document.querySelector(".bodyqqq").classList.add("sembunyi")
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    loadMoreData() {
        const data = this.state.organizeData;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            berita: slice
        })

    }

    perintah = () => {
        var perintah = document.createElement("script");
        perintah.src = "assets/perintah.js";
        document.getElementsByTagName("head")[0].appendChild(perintah);
    };

    componentDidMount = () => {
        if (localStorage.getItem("bahasaPilihan") === "english") {
            this.perintah();
        }
        this.beritaGet();
        // navbar
        window.onscroll = function () { scrollFunction() };
        var myNav = document.getElementById('header');

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                myNav.classList.remove("nav-transparent");
                myNav.classList.add("nav-berwarna");
            } else {
                myNav.classList.remove("nav-berwarna");
                myNav.classList.add("nav-transparent");
            }
        }

        // scroll nav dan for val
        var scriptku = document.createElement("script");
        scriptku.src = "assets/jscustom.js";
        document.getElementsByTagName("head")[0].appendChild(scriptku);

        // animation 
        var aos12 = document.createElement("script");
        aos12.src = "assets/aos.js";
        document.getElementsByTagName("head")[0].appendChild(aos12);
    }

    render() {
        return (
            <div>
                <Header />
                <div className="bodyqqq" style={{ marginTop: '4%' }}>
                    <div className="containerqq">
                        <div className="yellow bullet"></div>
                        <div className="red bullet"></div>
                        <div className="blue bullet"></div>
                        <div className="violet bullet"></div>
                    </div>
                </div>
                <div className="container pengu">
                    <div className="jumbotron" style={{ backgroundColor: 'transparent' }}>
                        <h3 className="ship3">
                            <div>News Portal</div>
                        </h3>
                        <br />
                        <div className="row">
                            {
                                this.state.berita.map(berita => {
                                    var date = new Date(berita.tgl_berita);
                                    var tahun = date.getFullYear();
                                    var bulan = date.getMonth();
                                    var tanggal = date.getDate();
                                    switch (bulan) {
                                        case 0: bulan = "January"; break;
                                        case 1: bulan = "February"; break;
                                        case 2: bulan = "March"; break;
                                        case 3: bulan = "April"; break;
                                        case 4: bulan = "May"; break;
                                        case 5: bulan = "June"; break;
                                        case 6: bulan = "July"; break;
                                        case 7: bulan = "August"; break;
                                        case 8: bulan = "September"; break;
                                        case 9: bulan = "October"; break;
                                        case 10: bulan = "November"; break;
                                        case 11: bulan = "December"; break;
                                        default:
                                    }
                                    var tglberita = tanggal + " " + bulan + " " + tahun;
                                    return <div className="col-xl-3" key={berita.id}>
                                        <div className="kotakku">
                                            <img src={linkgbr.brtLk + berita.gbr_berita + Keykui.keyKui} className="berita2" alt="" />
                                            <p className="textmu batasanhidup2">
                                                {berita.judul_berita}
                                            </p>

                                            <div className="footerbrt">
                                                <p>{tglberita}</p>
                                                <Link className="bacape indo" to={"beritaArtikel" + berita.id}>
                                                    baca selengkapnya <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                                                </Link >
                                                <Link className="bacape english hide" to={"beritaArtikel" + berita.id}>
                                                    read more <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                                                </Link >
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div>
                </div>
                <br />
                <br />
                <Footer />
            </div>
        );
    }
}

export default MorePeng;