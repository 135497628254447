import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Pengumuman from "../../components/pengumuman";
import Galeri from "../../components/galeri";
import Partnership from "../../components/partnership";
import Strukturorg from "../../components/strukturorg";
import Kontak from "../../components/kontak";
import Download from "../../components/download";
import Berita from "../../components/berita";

class Home extends Component {

  perintah = () => {
    var perintah = document.createElement("script");
    perintah.src = "assets/perintah.js";
    document.getElementsByTagName("head")[0].appendChild(perintah);
  };

  componentDidMount = () => {
    if (localStorage.getItem("bahasaPilihan") === "english") {
      this.perintah();
    }

    // navbar
    window.onscroll = function () { scrollFunction() };
    var myNav = document.getElementById('header');

    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        myNav.classList.remove("nav-transparent");
        myNav.classList.add("nav-berwarna");
      } else {
        myNav.classList.remove("nav-berwarna");
        myNav.classList.add("nav-transparent");
      }
    }

    // scroll nav dan for val
    var scriptku = document.createElement("script");
    scriptku.src = "assets/jscustom.js";
    document.getElementsByTagName("head")[0].appendChild(scriptku);

    // animation 
    var aos12 = document.createElement("script");
    aos12.src = "assets/aos.js";
    document.getElementsByTagName("head")[0].appendChild(aos12);
  }

  render() {
    function bukaTtg() {
      document.getElementById("ttgkui").classList.remove("ilang");
      document.getElementById("ttgkui").classList.add("modal");
    }
    function tutupTtg() {
      document.getElementById("ttgkui").classList.remove("modal");
      document.getElementById("ttgkui").classList.add("ilang");
    }

    const loginBtn2 = () => {
      if (localStorage.getItem("logus") == null && localStorage.getItem("nama_adm") == null) {
        return (
          <Link className="btn strukdid" id="md3" to="/login" data-aos="fade-up">
            <span className="indo">Masuk Sekarang &#128516;</span>
            <span className="english hide">&nbsp;&nbsp;&nbsp;&nbsp;Login Now&nbsp;&nbsp;&nbsp;&nbsp; &#128516;</span>
          </Link>
        )
      }
      else {
        return (
          <Link className="btn strukdid" id="md3" to="/pgDash" data-aos="fade-up">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dashboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#128516;</span>
          </Link>
        )
      }
    }

    return (
      <div>
        <section id="header" className="nav-transparent">
          <div className="navb">
            <a href="#header1" className="scrollhome">
              <img
                className="logokui"
                src="assets/logoasia.png"
                alt=""
              />
            </a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label
              className="menu-icon"
              htmlFor="menu-btn"
              style={{ marginTop: "6px" }}
            >
              <span className="navicon" />
            </label>
            <ul className="menu">
              <li className="gedeilang">
                <div className="dropdownhp">
                  <div className="row">
                    <a href="#">Lang &nbsp;<p className="fa fa-language"></p></a>
                  </div>
                  <div
                    className="dropdown-contenthp"
                    id="manukdadali"
                    style={{ marginLeft: "-38px" }}
                  >
                    <a href="#" type="button" id="suka" className="indonesia">
                      Indonesia
                    </a>
                    <a href="#"
                      type="button"
                      id="suka"
                      className="englishku"
                      style={{ marginTop: '2px' }}
                    >
                      English
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <Link to="/programkui" id="suka">
                  Program
                </Link>
              </li>
              <li>
                <div className="dropdown indo hayo">
                  <a href="#">
                    Tentang Kami &nbsp;
                    <span className="fa fa-caret-down"></span>
                  </a>
                  <div className="dropdown-content">
                    <a href="#visimisisec" id="suka" className="visiscroll">
                      Visi Misi
                    </a>
                    <a href="#struk" id="suka" style={{ marginTop: "-8px" }} className="strukscroll">
                      Struktur Organisasi
                    </a>
                  </div>
                </div>
                <div className="dropdown2 english hide hayo">
                  <a>
                    About Us &nbsp;<span className="fa fa-caret-down"></span>
                  </a>
                  <div className="dropdown-content2">
                    <a href="#visimisisec" id="suka" className="visiscroll">
                      vision and mission
                    </a>
                    <a href="#struk" id="suka" style={{ marginTop: "-8px" }} className="strukscroll">
                      Organizational structure
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <a href="#galeris" id="suka" className="scrollgaleri">
                  <span className="indo">Galeri</span>
                  <span className="english hide">Gallery</span>
                </a>
              </li>
              <li>
                <a href="#pengumuman" id="suka" className="scrollpeng">
                  <span className="indo">Pengumuman</span>
                  <span className="english hide">Announcement</span>
                </a>
              </li>
              <li>
                <a href="#partnership" id="suka" className="scrollpartner">
                  <span className="indo">Kerjasama</span>
                  <span className="english hide">Partnership</span>
                </a>
              </li>
              <li>
                <a href="#kontku" id="suka" className="scrollkon">
                  <span className="indo">Kontak</span>
                  <span className="english hide">Contact</span>
                </a>
              </li>
              <li>
                <a href="#download" id="suka" className="scrollkon">
                  <span className="indo">Unduhan</span>
                  <span className="english hide">Download</span>
                </a>
              </li>
              <li className="cilikilang">
                <div className="dropdown">
                  <div className="row">
                    <a href="#">Lang &nbsp;<p className="fa fa-language"></p></a>
                  </div>
                  <div
                    className="dropdown-content"
                    id="manukdadali"
                    style={{ marginLeft: "-38px" }}
                  >
                    <a href="#" type="button" id="suka" className="indonesia">
                      Indonesia
                    </a>
                    <a href="#"
                      type="button"
                      id="suka"
                      className="englishku"
                    >
                      English
                    </a>
                  </div>
                </div>
              </li>
              {/* <li>
                <a href="/login" className="btn btn-customku">
                  Login
                </a>
              </li> */}
            </ul>
          </div>
        </section>

        {/* banner  */}
        <div className="container">
          <div className="jumbotron" id="header1">
            <div className="row">
              <h1 id="md1" style={{ color: "white" }} data-aos="fade-right">
                <span className="indo"><b>Biro Kerjasama Institut Asia</b></span>
                <span className="english hide"><b>Cooperation Bureau Institut Asia</b></span>
              </h1>
              <p id="ttg" onClick={bukaTtg} style={{ cursor: 'pointer' }} data-aos="fade-right">
                <span className="indo">
                  baca selengkapnya
                </span>
                <span className="english hide">
                  click to read more
                </span>
              </p>
              <p id="md2" data-aos="fade-up">
                <span className="indo">
                  Kami mengundang Anda menjelajahi situs kami, terhubung dengan kami menemukan hubungan kerjasama internasional, memberikan dampak positif
                  bagi Institut Teknologi dan Bisnis Asia Malang. dengan situs ini kami memberikan informasi yang Anda butuhkan.
                  <br />
                  {/* <b style={{ fontSize: "23px" }}> </b> */}
                </span>
                <span className="english hide">
                  We invite you to explore our website, connect with us and discover the breadth of the Institut Teknologi dan
                  Bisnis Asia Malang’s global impact. Hopefully through this website we can help you provide the information you need.
                  <br />
                  {/* <b style={{ fontSize: "23px" }}> #ComeJoinWithUs</b> */}
                  {/* &#128516; */}
                </span>
              </p>
              {loginBtn2()}
            </div>
          </div>
        </div>

        {/* modal ttg kui  */}
        <div id="ttgkui" className="ilang">
          <img
            src="https://i.ibb.co/LSXvz0H/sodis180400032.jpg"
            style={{
              position: "absolute",
              width: "105px",
              zIndex: "1400",
              borderRadius: "50%",
              marginTop: "-57px",
              marginLeft: "10%",
            }}
          />
          <div className="modal-content">
            <button onClick={tutupTtg} className="close">
              ×
            </button>
            <p style={{ marginTop: "20px" }}>
              <span className="indo">
                Kami mengundang Anda menjelajahi situs kami, terhubung dengan kami menemukan hubungan kerjasama internasional, memberikan dampak positif
                bagi Institut Teknologi dan Bisnis Asia Malang. dengan situs ini kami memberikan informasi yang Anda butuhkan.
                <br />
                <br />
                <b style={{ fontSize: "16px" }}> #MariBergabung</b> &#128516;
              </span>
              <span className="english hide">
                We invite you to explore our website, connect with us and discover the breadth of the Institut Teknologi dan
                Bisnis Asia Malang’s global impact. Hopefully through this website we can help you provide the information you need.
                <br />
                <br />
                <b style={{ fontSize: "16px" }}> #ComeJoinWithUs</b> &#128516;
              </span>
            </p>
          </div>
        </div>

        {/* pengertian kui */}
        <div className="container" id="tentangkui">
          <div className="jumbotron" style={{ backgroundColor: "transparent" }}>
            <h3 className="ttgku indo">Apa itu Biro Kerjasama Institut Asia ?</h3>
            <h3 className="ttgku english hide">What is Cooperation Bureau Institut Asia ?</h3>
            <p id="tulisanku" style={{ textAlign: "center" }} data-aos="fade-up">
              <span className="indo">
                Biro Kerjasama Institut Asia adalah salah satu unit lembaga
                yang ada di Institut Teknologi dan Bisnis Asia Malang yang memiliki
                fungsi sebagai lembaga urusan kerjasama baik dengan lembaga maupun
                Perguruan Tinggi internasional. <Link to="/programkui">lihat pendaftaran</Link>
              </span>
              <span className="english hide">
                Cooperation Bureau Institut Asia is one of the units that represents relations
                with partners in international projects. We are open for international
                visitors and delegations students and lecturers.
                <Link to="/programkui"> see registration</Link>
              </span>
            </p>
          </div>
        </div>

        {/* visi misi  */}
        <section id="visimisisec">
          <div className="container visiboxku" data-aos="fade-right">
            <div className="jumbotron row" style={{ backgroundColor: 'transparent' }}>
              <div className="col-sm-6">
                <img src="assets/visi.png" alt="visi" className="visigmbr" />
              </div>
              <div className="col-sm-6">
                <h1 className="visiku">
                  <span className="indo">Visi.</span>
                  <span className="english hide">Vision.</span>
                </h1>
                <p className="ketvisiku">
                  <span className="indo">
                    Menjadikan Institut Teknologi dan Bisnis Asia Malang sebagai Perguruan Tinggi
                    berbasis Digital Bisnis yang dikenal secara global.
                  </span>
                  <span className="english hide">
                    Encourage Institut Teknologi dan Bisnis Asia Malang to be globally recognized as  a Digital Business University.
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="container misiboxku" data-aos="fade-up">
            <div className="jumbotron row" style={{ backgroundColor: 'transparent' }}>
              <div className="col-sm-6 tabletmu">
                <h1 style={{ textAlign: 'right' }} className="misiku">
                  <span className="indo">Misi.</span>
                  <span className="english hide">Mission.</span>
                </h1>
                <p style={{ textAlign: 'right' }} className="ketmisiku">
                  <span className="indo">
                    Aktif berpartisipasi mendukung visi dan misi Institut Teknologi dan Bisnis Asia Malang
                    yang berkaitan dengan hubungan kerjasama baik secara nasional maupun internasional serta
                    membangun civitas akademika yang kompetitif dalam skala nasional maupun internasional.
                  </span>
                  <span className="english hide">
                    Actively participates in supporting the vision and mission of the Institute of
                    Technology and Business Asia Malang related to cooperative relationships both nationally and internationally
                    as well as building a competitive academic community on a national and international scale.
                  </span>
                </p>
              </div>
              <div className="hpmu">
                <h1 style={{ textAlign: 'right' }} className="misiku2">
                  <span className="indo">Misi.</span>
                  <span className="english hide">Mission.</span>
                </h1>
                <p style={{ textAlign: 'right' }} className="ketmisiku2">
                  <span className="indo">
                    Aktif berpartisipasi mendukung visi dan misi Institut Teknologi dan Bisnis Asia Malang
                    yang berkaitan dengan hubungan kerjasama baik secara nasional maupun internasional serta
                    membangun civitas akademika yang kompetitif dalam skala nasional maupun internasional.
                  </span>
                  <span className="english hide">
                    Actively participates in supporting the vision and mission of the Institute of
                    Technology and Business Asia Malang related to cooperative relationships both nationally and internationally
                    as well as building a competitive academic community on a national and international scale.
                  </span>
                </p>
              </div>
              <div className="col-sm-6">
                <img src="assets/misi.png" alt="misi" className="misigmbr" />
              </div>
            </div>
          </div>
        </section>

        <Strukturorg />

        <Galeri />

        {/* pengumuman  */}
        <div className="container" id="pengumuman">
          <div className="jumbotron" style={{ backgroundColor: "transparent" }} data-aos="fade-up">
            <h1 className="pengumuman indo">Pengumuman.</h1>
            <h1 className="pengumuman english hide">Announcement.</h1>
          </div>
        </div>
        <div className="container">
          <div className="jumbotron" id="peng">
            <h3 className="ship3">
              <div className="indo">Pengumuman terbaru</div>
              <div className="english hide">Latest announcement</div>
            </h3>
            <br />
            <div className="row">
              <div className="col-md-12">
                <Pengumuman />
              </div>
            </div>
            <div style={{ marginTop: '70px' }}></div>
            <h3 className="ship3">
              <div className="indo">Artikel terbaru</div>
              <div className="english hide">Latest article</div>
            </h3>
            <br />
            <Berita />
            <div style={{ textAlign: 'center' }}>
              <Link
                to="/morearticle"
                className="btn btn-customku6"
              >
                <span className="indo">artikel lainya &nbsp;<i className="fa fa-angle-right"></i></span>
                <span className="english hide">more article &nbsp;<i className="fa fa-angle-right"></i></span>
              </Link>
            </div>
          </div>
        </div>

        <Partnership />

        <Kontak />

        <Download />

        <Footer />

      </div>
    );
  }
}

export default Home;
