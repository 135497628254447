import React, { Component } from 'react';
import keyastor from '../services/key_astor';
import API_ASTOR from '../services/api_astor';

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            un: '',
            ua: '',
            usd: '',
            user_name: '',
            user_password: '',
            filedatas: '',
            semongko: '',
            v_versi: '',
            b_versi: '',
            n_versi: '',
            Lot: [],
            cklink: '0',
            nm: ''
        }
    }

    async componentDidMount() {
        const lsun = this.props.match.params.usr
        const lspss = this.props.match.params.pss

        this.setState({
            user_name: lsun,
            user_password: lspss
        })

        const scp = this.props.match.params.un
        const res = await API_ASTOR.get('aplikasi/surat/search/lv/join/app/inview' + keyastor.baseURL + '&uid=' + scp + '&app=3')
        this.setState({
            un: res.data[0].user_id,
            uas: res.data[0].level_id,
            ua: res.data[0].level_id,
            usd: res.data[0].usid,
            v_versi: res.data[0].v_versi,
            b_versi: res.data[0].b_versi,
            n_versi: res.data[0].n_versi,
            Lot: res.data[0].lots,
            nm: res.data[0].user_nama
        })

        if (this.state.Lot == '') {
            localStorage.clear()
            sessionStorage.clear()
            this.setState({
                cklink: '0'
            })
            window.location.replace('/notdas')
        } else {
            if (this.state.Lot[0].akses == 0) {
                localStorage.clear()
                sessionStorage.clear()
                this.setState({
                    cklink: '0'
                })
                window.location.replace('/notdas')
            } else {
                if (this.state.b_versi == 1) {
                    this.setState({
                        semongko: 1,
                    })
                } else {
                    this.setState({
                        semongko: 0,
                    })
                }
                await API_ASTOR.post('login/staf/dash/in/ul' + keyastor.baseURL, this.state)
                    .then(Response =>
                        this.setState({
                            filedatas: Response.data[0].filedatas,
                        })
                    )
                if (this.state.filedatas == 1) {
                    sessionStorage.setItem("un", this.state.un)
                    sessionStorage.setItem("ak", this.state.Lot[0].akses)
                    sessionStorage.setItem("sid", this.state.usd)
                    localStorage.setItem("nama_adm", this.state.nm)
                    const ar = [{ "is verivy": "true", }]
                    localStorage.setItem('l', JSON.stringify(ar))
                    this.setState({
                        cklink: '1'
                    })
                    this.sesscek()
                } else {
                    this.setState({
                        cklink: '0'
                    })
                    window.location.replace('/notdas')
                }
            }
        }
    }

    sesscek() {
        if (sessionStorage.getItem("un") == null) {
            localStorage.clear()
            sessionStorage.clear()
            this.setState({
                cklink: '0'
            })
            window.location.replace('/notdas')
        }
        else {
            if (this.state.b_versi == 1) {
                localStorage.removeItem('s')
                sessionStorage.setItem("ifDash", "yes")
                window.location.replace('/pgDash')
            } else {
                if (this.state.semongko == 1) {
                    window.location.replace('/pgDash')
                } else {
                    localStorage.removeItem('s')
                    this.setState({
                        cklink: '0'
                    })
                    sessionStorage.setItem("ifDash", "yes")
                    window.location.replace('/pgDash')
                }
            }
        }
    }

    render() {
        return (
            <div className="bodyqq">
                <div class="containerqq">
                    <div class="yellow bullet"></div>
                    <div class="red bullet"></div>
                    <div class="blue bullet"></div>
                    <div class="violet bullet"></div>
                </div>
            </div>
        )
    }
}
