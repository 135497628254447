import React from 'react';
import { Fragment } from 'react';
import { Route } from 'react-router-dom';
import AddPeng from './pages/dashboard/pengumuman/addPeng';
import Dashboard from './pages/dashboard/dashboard';
import EditPeng from './pages/dashboard/pengumuman/editPeng';
import PengumumanDash from './pages/dashboard/pengumuman/pengumuman';
import DetailPengumuman from './pages/landing_page/DetailPengumuman';
import DetPeng from './pages/landing_page/DetPeng';
import Home from './pages/landing_page/Home';
import MorePeng from './pages/landing_page/MorePeng';
import Program from './pages/landing_page/Program';
import ArtDash from './pages/dashboard/artikel/artikel'
import AddArt from './pages/dashboard/artikel/addArt';
import EditArt from './pages/dashboard/artikel/editArt';
import LoginPage from './pages/dashboard/loginPage';
import loading from './pages/dashboard/templates/loading';
import notdas from './pages/dashboard/templates/notdash';
import loadingout from './pages/dashboard/templates/loadingout';
import linkdashkui from './services/authDash';
import OtentikasiPage from './pages/otentikasiPage';


function App() {
    return (
        <Fragment>
            {/* dashboard */}
            <Route path="/linkdashkui/:un/:usr/:pss" exact component={linkdashkui} />
            <Route path="/gagalotentikasi" exact component={OtentikasiPage} />
            {/* landing_page */}
            < Route path="/" exact component={Home} />
            <Route path="/programkui" exact component={Program} />
            <Route path="/morearticle" exact component={MorePeng} />
            <Route path="/beritaArtikel:beritaId" exact component={DetailPengumuman} />
            <Route path="/peng:pengId" exact component={DetPeng} />

            <Route path="/login" exact component={LoginPage} />
            <Route path="/loading/:un" exact component={loading} />
            <Route path="/notdas" exact component={notdas} />
            <Route path="/loadingout" exact component={loadingout} />
            {/* admin dashboard  */}
            <Route path="/Dash" exact component={Dashboard} />
            {/* pengumuman */}
            <Route path="/pgDash" exact component={PengumumanDash} />
            <Route path="/pgDashadd" exact component={AddPeng} />
            <Route path="/pgDashedit:pgId" exact component={EditPeng} />
            {/* artikel  */}
            <Route path="/artDash" exact component={ArtDash} />
            <Route path="/artDashadd" exact component={AddArt} />
            <Route path="/artDashedit:artId" exact component={EditArt} />
        </Fragment>
    );
}

export default App;