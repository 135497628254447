import React, { Component } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

class NavDash extends Component {
    render() {
        return (
            <Fragment>
                <nav id="sidebar">
                    <div className="sidebar-header">
                        <h3>Dashboard KUI Asia</h3>
                    </div>
                    <p style={{ padding: '20px', marginBottom: '-20px', backgroundColor: '#1e2a57' }}>
                        <i className="fas fa-user-circle"></i>&nbsp;&nbsp;
                        {localStorage.getItem("nama_adm")}
                    </p>
                    <div className="mb-2" />
                    <ul className="list-unstyled components">
                        <li className="">
                            <Link to="/" className="nava">Home</Link>
                        </li>
                        <li >
                            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                Page Layout
                            </a>
                            <ul className="collapse list-unstyled" id="homeSubmenu">
                                <li>
                                    <Link to="/pgDash" className="nava">Pengumuman</Link>
                                </li>
                                <li>
                                    <Link to="/artDash" className="nava">Artikel</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

export default NavDash;