import React, { Component } from 'react';
import { Link } from "react-router-dom";
import API from '../services/api';
import lkgbr from '../services/linkgbr';
import Keykui from '../services/key';

class Berita extends Component {

    // state 
    state = {
        judulku: "",
        capt: "",
        idbrt: ""
    }

    async componentDidMount() {
        await API.get('dash/tampil/art' + Keykui.keyKui,
            {
                mode: "no-cors",
                method: "GET"
            }
        ).then(res => {
            this.setState({
                gbresopo: lkgbr.brtLk + res.data[0].gbr_berita + Keykui.keyKui,
                judulku: res.data[0].judul_berita,
                capt: res.data[0].isi_berita,
                idbrt: res.data[0].id,
                statusbrt: res.status
            })
        })
        if (this.state.statusbrt == 200) {
            document.querySelector(".bodyqqqq").classList.add("sembunyi");
            document.querySelector(".beritaq").classList.remove("sembunyi");
        }
    };

    shouldComponentUpdate = () => {
        return true;
    }

    getSnapshotBeforeUpdate = () => {
        return null;
    }

    componentDidUpdate = () => { }

    componentWillUnmount = () => { }

    render() {
        return (
            <div>
                <div className="beritaq sembunyi">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={this.state.gbresopo} className="imgberita" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <p className="tulisanp">
                                {this.state.judulku}.
                        </p>
                            <div className="ketp batasanhidup">
                                <div dangerouslySetInnerHTML={{ __html: this.state.capt }}></div>
                            </div>
                            <br />
                            <Link className="bacap indo" to={"beritaArtikel" + this.state.idbrt}>
                                baca selengkapnya <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                            </Link>
                            <Link className="bacap english hide" to={"beritaArtikel" + this.state.idbrt}>
                                read more <span className="fa fa-arrow-circle-right" style={{ color: ' #f4a832' }}></span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="bodyqqqq">
                    <div className="containerqq">
                        <div className="yellow bullet"></div>
                        <div className="red bullet"></div>
                        <div className="blue bullet"></div>
                        <div className="violet bullet"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Berita;