import React, { Component } from 'react';

class Partnership extends Component {
    render() {
        return (
            <div>
                {/* partnership  */}
                <div className="container" id="partnership">
                    <div className="jumbotron kerjasama">
                        <h3 className="ship">
                            <span className="indo">Mitra Kerjasama Internasional</span>
                            <span className="english hide">International Partnership</span>
                        </h3>
                        <h3 className="ship2">
                            <div>Philipines</div>
                        </h3>
                        <div className="row" style={{ marginTop: '-35px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/nueva.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/pangasinan.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/iloilo.png" alt=""
                            />
                        </div>
                        <h3 className="ship2">
                            <div>Malaysia</div>
                        </h3>
                        <div className="row" style={{ marginTop: '-35px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/poltekbahiyah.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/polteksyed.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/poltekmerlimau.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/poltekazlan.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/poltekmelaka.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/poltekmersing.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/mara.jpg" alt=""
                            />
                        </div>
                        <h3 className="ship2">
                            <div>Thailand</div>
                        </h3>
                        <div className="row" style={{ marginTop: '-35px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/thailand.jpg" alt=""
                            />
                        </div>
                        <h3 className="ship2">
                            <div>Taiwan</div>
                        </h3>
                        <div className="row" style={{ marginTop: '-35px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/nanhua.png" alt=""
                            />
                        </div>
                        <h3 className="ship2">
                            <div>Timor Leste</div>
                        </h3>
                        <div className="row" style={{ marginTop: '-35px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/iontimor.png" alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="container" id="partnership">
                    <div className="jumbotron kerjasama">
                        <h3 className="ship">
                            <span className="indo">Mitra Kerjasama Nasional</span>
                            <span className="english hide">National Partnership</span>
                        </h3>
                        <div className="row" style={{ marginTop: '0px', marginBottom: '0px' }} >
                            <img className="imgpartnerships"
                                src="assets/partner/ciputra.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/petra.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/umb.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/panca.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/mandala.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/kesumanegara.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/amik.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/stie.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/widya.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/smk1pasuruan.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/ngasem.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/gedangan.png" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/cbn.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/jnt.jpg" alt=""
                            />
                            <img className="imgpartnerships"
                                src="assets/partner/frisidea.jpg" alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Partnership;