import React, { Component } from 'react';

class OtentikasiPage extends Component {
    render() {
        return (
            <div>
                <div align="center" style={{ paddingTop: '4%' }}>
                    <h1>Tidak Dapat Masuk Aplikasi KUI ?</h1>
                    <h2>Hubungi Telefon : 302 / Email : upt_si@asia.ac.id</h2>
                </div>
            </div>
        );
    }
}

export default OtentikasiPage;